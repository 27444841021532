"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _echarts = _interopRequireDefault(require("@/utils/echarts"));

var _resize = _interopRequireDefault(require("../mixins/resize"));

//
//
//
var _default2 = {
  name: 'ScatterChart',
  mixins: [_resize.default],
  props: {
    chartData: {
      type: Object,
      default: function _default() {}
    }
  },
  watch: {
    chartData: function chartData() {
      this.filterData();
    }
  },
  mounted: function mounted() {
    this.filterData();
  },
  methods: {
    filterData: function filterData() {
      var option = {
        grid: {
          left: 20,
          right: 30,
          bottom: 20,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // name: '日期',
          // splitArea: { show: false }, // 保留网格区域
          // splitNumber: 25,
          nameTextStyle: {
            fontSize: 14,
            color: '#333'
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid'
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '影响力',
          max: 1000,
          min: 0,
          nameTextStyle: {
            fontSize: 14,
            color: '#333'
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid'
            }
          },
          splitArea: {
            show: false
          } // 保留网格区域

        },
        dataZoom: [],
        series: [{
          data: [],
          label: {
            emphasis: {
              show: true,
              formatter: function formatter(param) {
                return '发文量：' + param.data[2];
              },
              position: 'top'
            },
            fontSize: 16,
            color: '#333'
          },
          type: 'scatter',
          symbolSize: function symbolSize(data) {
            return data[2] * 4;
          },
          itemStyle: {
            normal: {
              shadowBlur: 10,
              shadowColor: 'rgba(120, 36, 50, 0.5)',
              shadowOffsetY: 5,
              color: new _echarts.default.graphic.RadialGradient(0.4, 0.3, 1, [{
                offset: 0,
                color: 'rgb(251, 118, 123)'
              }, {
                offset: 1,
                color: 'rgb(204, 46, 72)'
              }])
            }
          }
        }]
      };
      option.yAxis.max = parseInt(this.chartData.max + 50);
      option.series[0].data = this.chartData.data; // option.dataZoom = this.chartData.dataZoom

      this.draw(option);
    },
    draw: function draw(option) {
      // 实例化echarts对象
      var myChart = _echarts.default.init(this.$refs.ScatterChart);

      myChart.setOption(option);
    }
  }
};
exports.default = _default2;