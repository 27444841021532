var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrap" }, [
    _vm.campaign && _vm.campaign.name
      ? _c(
          "main",
          { staticClass: "detail-page" },
          [
            _c(
              "el-row",
              { staticClass: "banner-wrap" },
              [
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "h3",
                    { staticClass: "ellipsis-1" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: { width: "100%", content: _vm.campaign.name }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [_vm._v(_vm._s(_vm.campaign.name))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 24, gutter: [20, 30] } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left btn-group",
                            attrs: { span: 24 }
                          },
                          [
                            _c("span", { staticClass: "mr-8" }, [
                              _vm._v(
                                "创建时间:" +
                                  _vm._s(
                                    _vm._f("formatUTCLocalDate")(
                                      _vm.campaign.startDate
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "mr-8" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.campaign.isPrivate
                                    ? "私密活动"
                                    : "公开活动"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "btn-primary mr-8" }, [
                              _vm._v(
                                "活动总预算  $" +
                                  _vm._s(
                                    _vm._f("toFixed2")(_vm.campaign.budget)
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "btn-primary" }, [
                              _vm._v(
                                "招募人数  " +
                                  _vm._s(_vm.campaign.recruitedInfluencers)
                              )
                            ]),
                            _vm._v(" "),
                            _vm.campaign.discountCode
                              ? _c(
                                  "span",
                                  { staticClass: "btn-primary mr-8" },
                                  [
                                    _vm._v(
                                      "折扣码  " +
                                        _vm._s(_vm.campaign.discountCode)
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { staticClass: "banner-main", attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "layout-lt", attrs: { span: 8 } },
                          [_c("vue-preview", { attrs: { slides: _vm.slide } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { staticClass: "layout-rt", attrs: { span: 16 } },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "label-item ellipsis-1",
                                    attrs: { span: 10 }
                                  },
                                  [
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "page.campaignDetail.prodectName"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("b", { staticClass: "ellipsis-1" }, [
                                        _vm._v(_vm._s(_vm.campaign.brand.name))
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("page.common.campaignType")
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "operation.camgpaingeTab." +
                                                _vm.campaign.campaignType
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _vm.industryAll
                                      ? _c("div", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "page.campaignDetail.industriesType"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "ellipsis-1" },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "bottom",
                                                    content: _vm.industryCon,
                                                    trigger: "hover"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        slot: "reference"
                                                      },
                                                      slot: "reference"
                                                    },
                                                    _vm._l(
                                                      _vm.campaign.industries,
                                                      function(item) {
                                                        return _c(
                                                          "span",
                                                          { key: item },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm
                                                                    .industryAll[
                                                                    item
                                                                  ]
                                                                ) +
                                                                " \n                        "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.areaAll
                                      ? _c("div", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "page.campaignDetail.targetArea"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "ellipsis-1" },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "bottom",
                                                    content: _vm.areaCon,
                                                    trigger: "hover"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "ellipsis-1",
                                                      attrs: {
                                                        slot: "reference"
                                                      },
                                                      slot: "reference"
                                                    },
                                                    _vm._l(
                                                      _vm.campaign.targetAreas,
                                                      function(item) {
                                                        return _c(
                                                          "span",
                                                          { key: item },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.areaAll[
                                                                    item
                                                                  ]
                                                                ) +
                                                                " \n                        "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "page.campaignDetail.campaiginTarget"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "page.collaboration.targetOptions." +
                                                _vm.campaign.campaignTarget
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "page.campaignDetail.sampleDistribution"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.campaign.pressKit
                                              ? _vm.$t(
                                                  "page.campaignDetail.pressKit"
                                                )
                                              : _vm.$t(
                                                  "page.campaignDetail.noPressKit"
                                                )
                                          )
                                        )
                                      ])
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "label-item label-tools",
                                    attrs: { span: 14 }
                                  },
                                  [
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "page.campaignDetail.expectCreatAt"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatUTCLocalDate")(
                                              _vm.campaign.startDate
                                            )
                                          ) +
                                            " ~ " +
                                            _vm._s(
                                              _vm._f("formatUTCLocalDate")(
                                                _vm.campaign.endDate
                                              )
                                            )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "page.campaignDetail.promoteWays"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "page.common." +
                                                _vm.campaign.contextType
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "page.campaignDetail.promotePlatforms"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "b",
                                        _vm._l(_vm.campaign.platforms, function(
                                          item
                                        ) {
                                          return _c("SvgIcon", {
                                            key: item,
                                            attrs: {
                                              "icon-class": item + "-on"
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "page.campaignDetail.refLink"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("b", { staticClass: "ellipsis-1" }, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              target: "_blank",
                                              href: _vm.campaign.refLink
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleOpen(
                                                  _vm.campaign.refLink
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.campaign.refLink))]
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _vm.campaign.assets.annex.length > 0
                                      ? _c("div", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "page.campaignDetail.content"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("b", [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    _vm.campaign.assets.annex[0]
                                                      .url,
                                                  target: "_blank",
                                                  rel: "noopener noreferrer"
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-download",
                                                  staticStyle: {
                                                    fontSize: "20px",
                                                    verticalAlign: "text-bottom"
                                                  }
                                                })
                                              ]
                                            )
                                          ])
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "article-info" },
              [
                _c("SubTitle", {
                  attrs: { title: _vm.$t("page.campaignDetail.brandTitle") }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "info" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.$t("page.campaignDetail.brandIntro")) + " "
                    )
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.brandIntroLimit
                          ? _vm.campaign.brand.description &&
                              _vm.campaign.brand.description.slice(
                                0,
                                _vm.brandIntroLimit
                              )
                          : _vm.campaign.brand.description
                      ) +
                      "\n        "
                  ),
                  _vm.campaign.brand.description &&
                  _vm.campaign.brand.description.length > 280
                    ? _c("span", { staticClass: "pointer pointer-tool" }, [
                        _vm.brandIntroLimit
                          ? _c("i", {
                              staticClass: "el-icon-arrow-down",
                              attrs: { type: "down" },
                              on: {
                                click: function($event) {
                                  _vm.brandIntroLimit = null
                                }
                              }
                            })
                          : _c("i", {
                              staticClass: "el-icon-arrow-down",
                              attrs: { type: "up" },
                              on: {
                                click: function($event) {
                                  _vm.brandIntroLimit = 280
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.$t("page.campaignDetail.productitro")) + " "
                    )
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.productDescLimit
                          ? _vm.campaign.productDescription &&
                              _vm.campaign.productDescription.slice(
                                0,
                                _vm.productDescLimit
                              )
                          : _vm.campaign.productDescription
                      ) +
                      "\n        "
                  ),
                  _vm.campaign.productDescription &&
                  _vm.campaign.productDescription.length > 280
                    ? _c("span", { staticClass: "pointer pointer-tool" }, [
                        _vm.productDescLimit
                          ? _c("i", {
                              staticClass: "el-icon-arrow-down",
                              attrs: { type: "down" },
                              on: {
                                click: function($event) {
                                  _vm.productDescLimit = null
                                }
                              }
                            })
                          : _c("i", {
                              staticClass: "el-icon-arrow-up",
                              attrs: { type: "up" },
                              on: {
                                click: function($event) {
                                  _vm.productDescLimit = 280
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "article-info" },
              [
                _c("SubTitle", {
                  attrs: {
                    title: _vm.$t("page.campaignDetail.contentRequirements")
                  }
                }),
                _vm._v(" "),
                _c("el-row", { staticClass: "info" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.$t("page.campaignDetail.contentRequirement")) +
                        " "
                    )
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.descLimit
                          ? _vm.campaign.description &&
                              _vm.campaign.description.slice(0, _vm.descLimit)
                          : _vm.campaign.description
                      ) +
                      "\n        "
                  ),
                  _vm.campaign.description &&
                  _vm.campaign.description.length > 280
                    ? _c("span", { staticClass: "pointer pointer-tool" }, [
                        _vm.descLimit
                          ? _c("i", {
                              staticClass: "el-icon-arrow-down",
                              attrs: { type: "down" },
                              on: {
                                click: function($event) {
                                  _vm.descLimit = null
                                }
                              }
                            })
                          : _c("i", {
                              staticClass: "el-icon-arrow-up",
                              attrs: { type: "up" },
                              on: {
                                click: function($event) {
                                  _vm.descLimit = 280
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info" }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.$t("page.campaignDetail.note")) + " ")
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.noteLimit
                          ? _vm.campaign.note &&
                              _vm.campaign.note.slice(0, _vm.noteLimit)
                          : _vm.campaign.note
                      ) +
                      "\n        "
                  ),
                  _vm.campaign.note && _vm.campaign.note.length > 280
                    ? _c("span", { staticClass: "pointer pointer-tool" }, [
                        _vm.noteLimit
                          ? _c("i", {
                              staticClass: "el-icon-arrow-down",
                              attrs: { type: "down" },
                              on: {
                                click: function($event) {
                                  _vm.noteLimit = null
                                }
                              }
                            })
                          : _c("i", {
                              staticClass: "el-icon-arrow-up",
                              attrs: { type: "up" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.noteLimit = 280
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ])
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }