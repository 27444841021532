"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchCampaignsList = fetchCampaignsList;
exports.patchAudit = patchAudit;
exports.getCampaignApprovals = getCampaignApprovals;
exports.getCampaign = getCampaign;
exports.getCooperation = getCooperation;
exports.getJobs = getJobs;

var _request = _interopRequireDefault(require("@/utils/request"));

// 获取活动列表数据
function fetchCampaignsList(params) {
  return (0, _request.default)({
    url: '/campaigns',
    method: 'get',
    params: params
  });
} // 同意或拒绝活动


function patchAudit(data) {
  return (0, _request.default)({
    url: "/campaign-approvals",
    method: 'post',
    data: data
  });
} // 获取审核记录


function getCampaignApprovals(params) {
  return (0, _request.default)({
    url: "/campaign-approvals",
    method: 'get',
    params: params
  });
} // 获取活动详情


function getCampaign(id, params) {
  return (0, _request.default)({
    url: "/campaigns/".concat(id),
    method: 'get',
    params: params
  });
} // 获取合作情况状态


function getCooperation(id, params) {
  return (0, _request.default)({
    url: "/campaigns?_id=".concat(id),
    method: 'get',
    params: params
  });
} // 获取合作情列表


function getJobs(id, params) {
  return (0, _request.default)({
    url: "/jobs?idCampaign=".concat(id),
    method: 'get',
    params: params
  });
}