"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
var _default2 = {
  name: 'Timebar',
  props: {
    position: {
      type: String,
      default: 'right'
    },
    times: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      value: 3
    };
  },
  methods: {
    handleTime: function handleTime(item, index) {
      this.value = item.value;
      this.$emit('handleTime', item);
    }
  }
};
exports.default = _default2;