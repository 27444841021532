var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["col-" + _vm.col, "post-item", "bg-white"],
      on: {
        click: function($event) {
          return _vm.handleViewPostDetail(_vm.link)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "post-item-img" },
        [
          _c("el-image", {
            attrs: { src: _vm.image ? _vm.image : _vm.defaultImg, fit: "cover" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "post-item-desc" }, [
            _c("p", { staticClass: "ellipsis-4" }, [_vm._v(_vm._s(_vm.desc))])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bars" }, [
        _c(
          "span",
          [
            _c("svg-icon", { attrs: { "icon-class": "share" } }),
            _vm._v(
              " " +
                _vm._s(_vm._f("numberFormatter")(parseInt(_vm.retweetNumber))) +
                " "
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "span",
          [
            _c("svg-icon", { attrs: { "icon-class": "msg" } }),
            _vm._v(
              " " +
                _vm._s(_vm._f("numberFormatter")(parseInt(_vm.commentNumber)))
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "span",
          [
            _c("svg-icon", { attrs: { "icon-class": "good" } }),
            _vm._v(
              " " + _vm._s(_vm._f("numberFormatter")(parseInt(_vm.likeNumber)))
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div")
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }