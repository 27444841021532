var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("router-view"),
      _vm._v(" "),
      _vm.showPage
        ? _c(
            "main",
            { staticClass: "campaigns-wrap" },
            [
              _c(
                "el-row",
                { staticClass: "filter-container clearfix" },
                [
                  _c(
                    "el-col",
                    [
                      _c("el-input", {
                        staticClass: "width-160",
                        attrs: {
                          clearable: "",
                          placeholder: "活动名称或品牌名称"
                        },
                        on: { change: _vm.handleFilter },
                        model: {
                          value: _vm.listQuery.name,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "name", $$v)
                          },
                          expression: "listQuery.name"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "width-140",
                          attrs: { clearable: "", placeholder: "活动状态" },
                          on: { change: _vm.handleFilter },
                          model: {
                            value: _vm.listQuery.status,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "status", $$v)
                            },
                            expression: "listQuery.status"
                          }
                        },
                        _vm._l(_vm.statusOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.resetForm("listQuery")
                            }
                          }
                        },
                        [_vm._v("重置")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { type: "primary" },
                          on: { click: _vm.handleFilter }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  staticClass: "table-wrap",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.list,
                    border: "",
                    fit: "",
                    stripe: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "活动ID",
                      "min-width": "146",
                      align: "left",
                      fixed: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [_c("span", [_vm._v(_vm._s(row._id))])]
                          }
                        }
                      ],
                      null,
                      false,
                      496189558
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "活动名称",
                      "min-width": "120",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [_c("span", [_vm._v(_vm._s(row.name))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2611166627
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "品牌名称",
                      "min-width": "120",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("span", [_vm._v(_vm._s(row.brand.name))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3590277078
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "私密性",
                      "min-width": "60",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(_vm._s(row.isPrivate ? "私密" : "公开"))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4212055901
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "总预算($)",
                      "min-width": "90",
                      align: "center",
                      sortable: "",
                      prop: "budget"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm._f("toFixed2")(row.budget)))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3437677351
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "已结算($)",
                      "min-width": "90",
                      align: "center",
                      sortable: "",
                      prop: "budget"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toFixed2")(
                                      row.jobSum.totalPaid || 0
                                    )
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2285313070
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "平台抽佣($)",
                      "min-width": "104",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toFixed2")(_vm.commission(row))
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2281353632
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "活动状态",
                      "min-width": "100",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: _vm.comCampaignsStatus(row.status)
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("activeState")(row.status)
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1475237141
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "提交时间",
                      "min-width": "130",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatUTCDate")(row.submittedAt)
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4174966204
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "120",
                      align: "center",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.toCampaignsDetail(row)
                                            }
                                          }
                                        },
                                        [_vm._v("活动详情")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            row.status !== "inApproval" &&
                                            row.status !== "rejected",
                                          expression:
                                            "\n                row.status !== 'inApproval' && row.status !== 'rejected'\n              "
                                        }
                                      ],
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.toCooperation(row)
                                            }
                                          }
                                        },
                                        [_vm._v("合作情况")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row.status === "inApproval",
                                          expression:
                                            "row.status === 'inApproval'"
                                        }
                                      ],
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { width: "66%" },
                                          attrs: {
                                            type: "success",
                                            size: "mini"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleOpenTableVisible(
                                                row,
                                                "审核结论"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("审核")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row.status !== "inApproval",
                                          expression:
                                            "row.status !== 'inApproval'"
                                        }
                                      ],
                                      staticStyle: { margintop: "10px" },
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { width: "66%" },
                                          attrs: { size: "mini", plain: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleOpenTableVisible(
                                                row,
                                                "审核记录"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("审核记录")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3535069195
                    )
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0"
                  }
                ],
                attrs: { total: _vm.total, page: _vm.page, limit: _vm.limit },
                on: {
                  "update:page": function($event) {
                    _vm.page = $event
                  },
                  "update:limit": [
                    function($event) {
                      _vm.limit = $event
                    },
                    _vm.updateLimit
                  ],
                  pagination: _vm.getList
                }
              }),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.dialogTitle,
                    visible: _vm.dialogTableVisible,
                    width: "80%"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogTableVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dialogTitle === "审核结论",
                          expression: "dialogTitle === '审核结论'"
                        }
                      ],
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.ruleForm,
                        "status-icon": "",
                        rules: _vm.rules,
                        "label-width": "auto",
                        "label-position": "top"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "isThrough" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.isThrough,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "isThrough", $$v)
                                },
                                expression: "ruleForm.isThrough"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: "approved" } }, [
                                _vm._v("通过")
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "rejected" } }, [
                                _vm._v("驳回")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleForm.isThrough === "rejected",
                              expression: "ruleForm.isThrough === 'rejected'"
                            }
                          ],
                          attrs: { label: "理由及整改意见", prop: "reason" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autocomplete: "off",
                              placeholder:
                                "请尽量与活动详情使用语言保持一致，简单说明说明驳回理由，并给出详细有指导性的整改意见",
                              autosize: { minRows: 6, maxRows: 12 }
                            },
                            model: {
                              value: _vm.ruleForm.reason,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "reason", $$v)
                              },
                              expression: "ruleForm.reason"
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-gray-700" }, [
                            _vm._v(
                              "\n            常见问题1：文字内容描述混乱。The text content description is\n            confusing.\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-gray-700" }, [
                            _vm._v(
                              "\n            常见问题2：文字描述违规或涉及敏感词。The text describes violations\n            or involves sensitive words.\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-gray-700" }, [
                            _vm._v(
                              "\n            常见问题3：上传图片/音视频不清晰或者无法下载。Upload\n            pictures/audio and video are not clear or cannot be downloaded.\n          "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "h3",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dialogTitle === "审核结论",
                          expression: "dialogTitle === '审核结论'"
                        }
                      ],
                      staticClass: "mb-8"
                    },
                    [_vm._v("审核意见")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.amendments,
                        border: "",
                        "highlight-current-row": ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          width: "50",
                          align: "center",
                          type: "index"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "结论",
                          "min-width": "40",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        row.status === "rejected"
                                          ? "驳回"
                                          : "通过"
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2503760694
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "理由及整改意见",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(row.content || "--"))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2344784269
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核发起时间",
                          "min-width": "70",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatUTCDate")(row.submittedAt)
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4174966204
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核完成时间",
                          "min-width": "70",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatUTCDate")(row.createdAt)
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1199273149
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核用时",
                          "min-width": "60",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$moment
                                          .duration(row.approvalTime)
                                          .days() +
                                          "天" +
                                          _vm.$moment
                                            .duration(row.approvalTime)
                                            .hours() +
                                          "小时" +
                                          _vm.$moment
                                            .duration(row.approvalTime)
                                            .minutes() +
                                          "分"
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1912409226
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核人",
                          "min-width": "90",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(row.idOperatorUser.name))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3975022545
                        )
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogTableVisible = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("确 定")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }