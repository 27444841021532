var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "time-bars", style: { textAlign: _vm.position } },
    _vm._l(_vm.times, function(item, index) {
      return _c(
        "span",
        {
          key: item.name,
          class: _vm.value === item.value ? "active" : "",
          on: {
            click: function($event) {
              return _vm.handleTime(item, index)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(item.name) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }