var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subtitle" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [_c("span", [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _vm._t("tip")],
        2
      ),
      _vm._v(" "),
      _vm._t("tool")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }