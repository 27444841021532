var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("router-view"),
      _vm._v(" "),
      _vm.editUserDialogVisible
        ? _c("edit-user-dialog", {
            attrs: { user: _vm.editUser, "role-options": _vm.roleOptions },
            on: {
              userUpdated: _vm.getList,
              closeDialog: _vm.handleCloseEditUserDialog
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.createUserDialogVisible
        ? _c("create-user-dialog", {
            attrs: { "role-options": _vm.roleOptions },
            on: {
              userCreated: _vm.getList,
              closeDialog: _vm.handleCloseCreateUserDialog
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPage
        ? _c(
            "main",
            { staticClass: "user-overview" },
            [
              _c(
                "el-row",
                { staticClass: "filter-container clearfix" },
                [
                  _c(
                    "el-col",
                    [
                      _c("label", [_vm._v("综合查询")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "width-200",
                        attrs: { placeholder: "姓名或邮箱", size: "medium" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleFilter($event)
                          }
                        },
                        model: {
                          value: _vm.listQuery.keyword,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "keyword", $$v)
                          },
                          expression: "listQuery.keyword"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.resetForm } }, [
                        _vm._v("重置")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { type: "primary" },
                          on: { click: _vm.handleFilter }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { type: "primary" },
                          on: { click: _vm.handleCreateUser }
                        },
                        [_vm._v("新建")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  staticClass: "table-wrap",
                  attrs: {
                    data: _vm.list,
                    "default-sort": { prop: "createdAt", order: "descending" },
                    border: "",
                    stripe: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "姓名", "min-width": "200", align: "left" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.name
                                ? _c("span", [_vm._v(_vm._s(row.name))])
                                : _c("span", [_vm._v("--")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3109066775
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createdAt",
                      sortable: "",
                      "min-width": "110",
                      align: "left"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatUTCDate")(
                                      row.createdAt,
                                      "YYYY-MM-DD"
                                    )
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4119576081
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "邮箱", "min-width": "240", align: "left" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.email
                                ? _c("span", [_vm._v(_vm._s(row.email))])
                                : _c("span", [_vm._v("--")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      414077175
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "角色", "min-width": "240", align: "left" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return _vm._l(row.roles, function(role) {
                              return _c(
                                "el-tag",
                                {
                                  key: role._id,
                                  staticClass: "mr-8 mb-8",
                                  attrs: { type: "success" }
                                },
                                [_vm._v(_vm._s(role.label))]
                              )
                            })
                          }
                        }
                      ],
                      null,
                      false,
                      2885640297
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      "min-width": "150",
                      align: "left",
                      "class-name": "status-col"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.active
                                ? _c("span", [
                                    _c("span", {
                                      staticClass: "active-indicator active"
                                    }),
                                    _vm._v("启用")
                                  ])
                                : _c("span", [
                                    _c("span", {
                                      staticClass: "active-indicator inactive"
                                    }),
                                    _vm._v("禁用")
                                  ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2357645531
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "left", label: "操作", "min-width": "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.type !== "super_admin"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleEdit(row)
                                        }
                                      }
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2357616972
                    )
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0"
                  }
                ],
                attrs: { total: _vm.total, page: _vm.page, limit: _vm.limit },
                on: {
                  "update:page": function($event) {
                    _vm.page = $event
                  },
                  "update:limit": [
                    function($event) {
                      _vm.limit = $event
                    },
                    _vm.updateLimit
                  ],
                  pagination: _vm.getList
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }