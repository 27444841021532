"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _default = {
  install: function install(Vue) {
    var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '$_';
    Object.defineProperty(Vue.prototype, name, {
      value: _lodash.default
    });
  }
};
exports.default = _default;