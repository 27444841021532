"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  route: {},
  operation: {
    'camgpaingeTab': {
      'all': '全部',
      'free': '免费测评',
      'quote': '报价测评',
      'pricing': '定价测评'
    }
  },
  page: {
    'common': {
      'campaignType': '活动类型',
      'video': '视频',
      'article': '图文',
      'articleOrVideo': '图文或视频'
    },
    'collaboration': {
      'shareLink': '活动分享链接',
      'isPrivate': '该活动为私密活动，无法分享',
      'cancelled': '该活动已暂停招募，无法分享',
      'finished': '该活动已结束，无法分享',
      'targetOptions': {
        'exposure': '增加品牌曝光量',
        'sales': '提高产品销量',
        'other': '其他'
      },
      'noCollaboration': "你目前还没有跟任何品牌建立合作，快去 <a href='/campaigns'>发现活动</a> 申请吧！",
      'sample': '样品',
      'addres': '地址',
      'acceptance': '验收状态',
      'deliveryInformation': '收货信息',
      'deliveryInformationInfo': '（企业确认合作前可修改收货信息）',
      'postReleaseInfo': {
        'deliveryPlatform': '品牌指定平台',
        'title': '推文发布说明',
        'publish': '确认发布',
        'dataLoading': '数据检测中…',
        'dataFail': '数据获取失败',
        'dataFailTip': '（注意确认推文已发布并附带完整推广链接）',
        'dataPublished': '发布成功',
        'refresh': '刷新',
        'reTry': '重试',
        'list': ['请确保你的推广内容里面附带下方的<b>推广链接</b> ，以便对推广效果进行追踪；', '完成推文发布后请尽快确认发布，否则无法进行数据追踪；', '提交后等待品牌验收，品牌验收后代表合作完成，若为报价测评相关收益将即刻发放至你的个人钱包。'],
        'tips': '请至少将内容发布至任一个品牌指定的平台。'
      },
      'sponsoredLinks': '附带推广链接获取',
      'tweetRelease': '推文发布',
      'brandAcceptance': '品牌验收',
      'brandAcceptanced': '品牌已验收',
      'noBrandAcceptanced': '待品牌验收',
      'coverToWallet': "你的活动收益已经发放至你的<a href='/wallet'>钱包</a>",
      'trackData': '数据追踪',
      'cooperationBudget': '合作预算',
      'campaignConfim': '合作确认',
      'smapleSend': '样品发放',
      'postReleased': '推文发布',
      'campaignPublished': '合作验收',
      'share': '分享'
    },
    'campaignDetail': {
      'totalBuget': '总预算',
      'salesCommissionNumber': '招募人数',
      'discountCode': '折扣码',
      'applayCampaign': '申请活动',
      'prodectName': '品牌名称 ',
      'industry': '所属行业',
      'industriesType': '行业名称',
      'targetArea': '目标区域',
      'campaiginTarget': '活动目标',
      'sampleDistribution': '样品发放',
      'expectCreatAt': '期望发文时间',
      'promoteWays': '推广内容形式',
      'promotePlatforms': '推广平台',
      'logistics': '物流信息',
      'updateAt': '更新时间',
      'refLink': '原推广链接',
      'content': '推广物料下载',
      'logisticNumber': '物流单号',
      'postRelease': '确认发布',
      'operation': '操作',
      'uploadContent': '上传内容',
      'reUpload': '重新上传',
      'brandTitle': '品牌/产品信息',
      'brandIntro': '品牌介绍',
      'productitro': '产品介绍',
      'contentRequirements': '要求',
      'contentRequirement': '内容要求',
      'note': '避免事项',
      'pressKit': '实物发放',
      'noPressKit': '非实物发放',
      'video': '视频',
      'yes': '是',
      'no': '否',
      'operationalHeat': '推文发布数据统计',
      'cooperationIncome': '合作收益',
      'cooperationConfirm': '合作确认',
      'brandName': '品牌名称',
      'brandLink': '推广链接',
      'postAdress': '帖子地址',
      'contentForm': '内容形式',
      'description': '内容要求',
      'copy': '复制',
      'send': '发送',
      'cooperationPlatform': '合作平台',
      'cooperationOffer': '合作报价',
      'thePhrase': ['请问什么时候寄送样品给我？', '不好意思，报价太低了，我接受不了。', '合作愉快！', '报价可以再提高一些么吗？', '好的，谢谢。'],
      'quoteTip': '提示：仅有三次变更提交报价的机会，请对方洽谈好后再提交报价。',
      'quoteCount': '剩余{count}次'
    }
  }
};
exports.default = _default;