"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _table = _interopRequireDefault(require("@/mixin/table"));

var _editUserDialog = _interopRequireDefault(require("./components/edit-user-dialog"));

var _createUserDialog = _interopRequireDefault(require("./components/create-user-dialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var listQuery = {
  keyword: undefined
};
var _default = {
  name: 'ManageUsers',
  components: {
    EditUserDialog: _editUserDialog.default,
    CreateUserDialog: _createUserDialog.default
  },
  mixins: [_table.default],
  data: function data() {
    return {
      listQuery: this.$_.cloneDeep(listQuery),
      editUserDialogVisible: false,
      editUser: undefined,
      createUserDialogVisible: false,
      roleOptions: undefined,
      roleDataLoading: false
    };
  },
  computed: {
    showPage: function showPage() {
      return this.$route.name === 'ManageUsers';
    }
  },
  created: function created() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      if (this.showPage) {
        this.getList();
        this.getRoleData();
      }
    },
    getRoleData: function getRoleData() {
      var _this = this;

      this.roleDataLoading = true;

      _api.default.getRoles().then(function (roles) {
        _this.roleDataLoading = false;
        _this.roleOptions = roles.data;
      }).catch(function () {
        _this.roleDataLoading = false;
      });
    },
    getList: function getList() {
      var _this2 = this;

      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        keyword: this.listQuery.keyword || undefined,
        '$skip': (this.page - 1) * this.limit,
        '$limit': this.limit
      });
      this.listLoading = true;

      _api.default.getAdminUsers(params).then(function (res) {
        _this2.list = res.data;
        _this2.total = res.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    handleEdit: function handleEdit(user) {
      this.editUser = this.$_.cloneDeep(user);
      this.editUser.roles = this.editUser.roles.map(function (role) {
        return role._id;
      });
      this.editUserDialogVisible = true;
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.listQuery = this.$_.cloneDeep(listQuery);
      this.handleFilter();
    },
    handleCloseEditUserDialog: function handleCloseEditUserDialog() {
      this.editUserDialogVisible = false;
    },
    handleCreateUser: function handleCreateUser() {
      this.createUserDialogVisible = true;
    },
    handleCloseCreateUserDialog: function handleCloseCreateUserDialog() {
      this.createUserDialogVisible = false;
    }
  }
};
exports.default = _default;