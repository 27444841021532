"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _auth = require("@/utils/auth");

var _router = require("@/router");

var state = {
  token: (0, _auth.getToken)(),
  name: '',
  avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
  uid: (0, _auth.getUid)(),
  roles: []
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    (0, _auth.setToken)(token);
    state.token = token;
  },
  SET_ID: function SET_ID(state, uid) {
    state.uid = uid;
    (0, _auth.setUid)(uid);
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var email = userInfo.email,
        password = userInfo.password;
    return new Promise(function (resolve, reject) {
      _api.default.login({
        email: email.trim(),
        password: password,
        strategy: 'local'
      }).then(function (response) {
        console.log('获取token :', response);
        var data = response;
        commit('SET_TOKEN', data.accessToken);
        commit('SET_ID', data.user._id);
        console.log('state :', state);
        resolve();
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      // 通过uid 获取数据
      _api.default.getInfo((0, _auth.getUid)()).then(function (response) {
        console.log('获取用户信息 :', response);
        var data = response;

        if (!data) {
          reject('验证失败，请重新登录');
        }

        console.log('获取用户信息 :', data);
        var roles = data.roles,
            name = data.name,
            permissions = data.permissions;
        commit('SET_NAME', name);
        commit('SET_ROLES', roles);
        commit('SET_PERMISSIONS', permissions);
        resolve({
          roles: roles
        });
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      _api.default.logout().then(function () {
        commit('SET_TOKEN', '');
        commit('SET_ID', '');
        commit('SET_NAME', '');
        commit('SET_ROLES', []);
        (0, _auth.removeToken)(); // 移除token

        (0, _router.resetRouter)();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      (0, _auth.removeToken)();
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;