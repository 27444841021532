var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑角色",
        width: "400px",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "editRoleForm",
          attrs: {
            model: _vm.editRoleForm,
            rules: _vm.editRoleRules,
            autocomplete: "off",
            "label-position": "left",
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "label", label: "名称", required: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入名称", size: "medium" },
                on: { change: _vm.handleInputChange },
                model: {
                  value: _vm.editRoleForm.label,
                  callback: function($$v) {
                    _vm.$set(_vm.editRoleForm, "label", $$v)
                  },
                  expression: "editRoleForm.label"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "permissions", label: "角色权限", required: "" } },
            [
              _c("el-tree", {
                ref: "permissionsTree",
                attrs: {
                  data: _vm.permissionsTree,
                  "show-checkbox": "",
                  "node-key": "_id",
                  "default-expand-all": "",
                  "default-checked-keys": _vm.defaultPermissions
                },
                on: { "check-change": _vm.handlePermissionsTreeChange }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleUpdateRole }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }