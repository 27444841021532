"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _util = require("@/utils/util");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CardNumber',
  props: {
    col: {
      type: Number,
      default: 3
    },
    id: {
      type: [Number, String],
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    platformMediaVale: {
      type: [Number, String],
      default: 0
    },
    retweetNumber: {
      type: [Number, String],
      default: 0
    },
    commentNumber: {
      type: [Number, String],
      default: 0
    },
    likeNumber: {
      type: [Number, String],
      default: 0
    },
    currency: {
      type: String,
      default: 'cny'
    },
    desc: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      defaultImg: require('@/assets/images/common/default-img.png')
    };
  },
  methods: {
    bloomFilter: _util.bloomFilter,
    handleViewPostDetail: function handleViewPostDetail(link) {
      this.$emit('handleViewPostDetail', link);
    }
  }
};
exports.default = _default;