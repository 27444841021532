"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _path = _interopRequireDefault(require("path"));

var _i18n = require("@/utils/i18n");

var _validate = require("@/utils/validate");

var _Item = _interopRequireDefault(require("./Item"));

var _Link = _interopRequireDefault(require("./Link"));

var _FixiOSBug = _interopRequireDefault(require("./FixiOSBug"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarItem',
  components: {
    Item: _Item.default,
    AppLink: _Link.default
  },
  mixins: [_FixiOSBug.default],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {};
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user'])), {}, {
    hasPermission: function hasPermission() {
      if (!this.user) return false;
      if (typeof this.item.permissions === 'undefined') return true;
      var permissions = this.item.permissions;

      for (var i = 0; i < permissions.length; i += 1) {
        if (this.user.permissions.includes(permissions[i])) {
          return true;
        }
      }

      return false;
    }
  }),
  methods: {
    hasOneShowingChild: function hasOneShowingChild() {
      var _this = this;

      var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var parent = arguments.length > 1 ? arguments[1] : undefined;
      var showingChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this.onlyOneChild = item;
          return true;
        }
      }); // When there is only one child router, the child router is displayed by default

      if (showingChildren.length === 1) {
        return true;
      } // Show parent if there are no child router to display


      if (showingChildren.length === 0) {
        this.onlyOneChild = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, parent), {}, {
          path: '',
          noShowingChildren: true
        });
        return true;
      }

      return false;
    },
    resolvePath: function resolvePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }

      if ((0, _validate.isExternal)(this.basePath)) {
        return this.basePath;
      }

      return _path.default.resolve(this.basePath, routePath);
    },
    generateTitle: _i18n.generateTitle
  }
};
exports.default = _default;