"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _table = _interopRequireDefault(require("@/mixin/table"));

var _EmailTpl = _interopRequireDefault(require("../components/EmailTpl"));

var _vue = _interopRequireDefault(require("vue"));

var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
_vue.default.use(_vueClipboard.default);

var listQuery = {
  displayName: undefined,
  area: '',
  platforms: undefined,
  industries: undefined,
  source: undefined,
  date: undefined,
  startAt: undefined,
  endAt: undefined,
  priority: undefined,
  status: undefined,
  referral: undefined
};
var _default = {
  name: 'KolFollowUp',
  components: {
    EmailTpl: _EmailTpl.default
  },
  mixins: [_table.default],
  data: function data() {
    return {
      listQuery: this.$_.clone(listQuery),
      downloadLoading: false,
      emailLoadLoading: false,
      emailDialog: false,
      multipleSelection: [],
      emailObject: {},
      message: 'https://baidu.com'
    };
  },
  computed: {
    showPage: function showPage() {
      return this.$route.name === 'KolFollowUp';
    }
  },
  created: function created() {
    this.getData();
  },
  methods: {
    onCopy: function onCopy(e) {
      this.$message({
        message: '你已经复制：' + e.text,
        type: 'success'
      });
    },
    claimTicket: function claimTicket(ticketData) {
      _api.default.updateSupportTicket(ticketData._id, {
        assignedTo: this.$store.state.user.uid,
        status: 'inProgress'
      });
    },
    handleChasingLead: function handleChasingLead(ticketData) {
      if (ticketData.intercomLeadId) {
        window.open("https://app.intercom.com/a/apps/gy2sapjn/users/".concat(ticketData.intercomLeadId));
      }
    },
    onError: function onError(e) {
      this.$message({
        message: '复制失败',
        type: 'error'
      });
    },
    sendEmail: function sendEmail() {
      _api.default.sendMail(this.emailObject);

      this.emailDialog = false;
    },
    copyMyLike: function copyMyLike() {
      console.log('9999');
    },
    openMailWindow: function openMailWindow(ticketData) {
      var body = "Hi ".concat(ticketData.kolInfo.navDisplayName, "<br>\nThis is ").concat(this.$store.state.user.name, " from Navigate Network.\nWe have a product from <b>").concat(ticketData.campaign.brand.name, "</b> for you.<br>\nYou can view more information about the campaign at<br> ").concat(process.env.VUE_APP_TRACKING_API, "/c/").concat(ticketData.campaign.shortLink);
      this.emailObject = {
        subject: 'We have a new campaign you might be interested in',
        body: body,
        intercomLeadId: ticketData.intercomLeadId
      };
      this.emailDialog = true;
    },
    handleEmail: function handleEmail() {
      var influcerEmails = this.multipleSelection.filter(function (val) {
        return val.email !== '';
      });
      influcerEmails.push({
        displayName: 'youwendudecoder',
        email: 'liujixing989@gmail.com'
      });

      if (influcerEmails.length <= 0) {
        this.$message({
          type: 'warning',
          message: '没有邮件可以发送'
        });
        return;
      }

      this.emailDialog = true;
    },
    getData: function getData() {
      if (this.showPage) {
        this.getList();
        this.getFieldsManager();
      }
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        area: this.listQuery.area[1] ? this.listQuery.area[1] : this.listQuery.area[0],
        '$skip': (this.page - 1) * this.limit,
        '$limit': this.limit
      });
      delete params.date;

      _api.default.getSupportTickets().then(function (res) {
        console.log('res-getFollowUpList :', res);
        _this.listLoading = false;
        _this.list = res.data;
        _this.total = res.total;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleDate: function handleDate(row) {
      this.listQuery.startAt = this.formatUtcDate(this.listQuery.date[0]);
      this.listQuery.endAt = this.formatUtcDate(this.listQuery.date[1]);
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    handleAddKol: function handleAddKol() {
      this.$router.push({
        name: 'AddKol'
      });
    },
    handleEditKOL: function handleEditKOL(row) {
      this.$router.push({
        name: 'EditKol',
        params: {
          id: row.idUser
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    comPlatform: function comPlatform(platforms) {
      var platform = [];
      platforms.forEach(function (ele) {
        switch (ele.platform) {
          case 'youtube':
            platform.push('youtube');
            break;

          case 'weibo':
            platform.push('微博');
            break;

          default:
            break;
        }
      });
      return platform;
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      if (this.multipleSelection.length) {
        this.downloadLoading = true;
        Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
        }).then(function (excel) {
          var tHeader = ['kol昵称', '区域', '行业', '社交平台', '联系邮箱', '联系电话', '来源渠道', '创建时间', '跟进时间', '跟进人', '优先级'];
          var filterVal = ['displayName', 'area', 'industries', 'platforms', 'email', 'contactPhone', 'source', 'createAt', 'status', 'referral', 'priority'];
          var list = _this2.multipleSelection;
          var mapList = list.map(function (ele) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, ele), {}, {
              platforms: _this2.comPlatform(ele.platforms).join('/'),
              area: _this2.comAreaDeep(ele.area),
              industries: _this2.comIndustries(ele.industries).join('/'),
              source: _this2.comSource(ele.source).name,
              status: _this2.comFollowStatus(ele.status).name,
              priority: _this2.comPriority(ele.priority).name,
              referral: _this2.comReferrer(ele.referral),
              createAt: _this2.formatUTCDate(ele.createAt)
            });
          });

          var data = _this2.formatJson(filterVal, mapList);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: _this2.filename
          });

          _this2.$refs.multipleTable.clearSelection();

          _this2.downloadLoading = false;
        });
      } else {
        this.$message({
          message: '请至少选择一个',
          type: 'warning'
        });
      }
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          return v[j];
        });
      });
    },
    resetForm: function resetForm() {
      this.listQuery = this.$_.clone(listQuery);
      this.handleFilter();
    },
    handleClose: function handleClose() {
      this.emailDialog = false;
    }
  }
};
exports.default = _default;