"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _config = _interopRequireDefault(require("@/config/config"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var listQuery = {
  account: null,
  createdAtStart: null,
  createdAtEnd: null,
  status: null,
  payeeAccount: null,
  payeeName: null,
  email: null,
  updatedAtStart: null,
  updatedAtEnd: null
};
var _default = {
  data: function data() {
    return {
      listQuery: this.$_.clone(listQuery),
      page: 1,
      limit: 10,
      total: 0,
      statusOptions: [{
        name: '成功',
        value: 'success'
      }, {
        name: '待审核',
        value: 'pending'
      }, {
        name: '已驳回',
        value: 'reject'
      }, {
        name: '失败',
        value: 'failed'
      }],
      list: [],
      listLoading: false,
      pickerOptions: _config.default.pickerOptions,
      date: null,
      updateDate: null,
      ids: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    filterStatus: function filterStatus(status) {
      var statusMap = {
        success: function success() {
          return '成功';
        },
        approved: function approved() {
          return '已同意';
        },
        failed: function failed() {
          return '失败';
        },
        pending: function pending() {
          return '待审核';
        },
        reject: function reject() {
          return '已驳回';
        }
      };
      return statusMap[status]();
    },
    formatUTCDate: function formatUTCDate(utc_datetime) {
      var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD';
      return this.$moment(utc_datetime).format(fmt);
    },
    handleDate: function handleDate(row) {
      if (row) {
        this.listQuery.createdAtStart = this.formatUTCDate(this.date[0]);
        this.listQuery.createdAtEnd = this.formatUTCDate(this.date[1]);
      } else {
        this.listQuery.createdAtStart = null;
        this.listQuery.createdAtEnd = null;
      }
    },
    handleUpdataDate: function handleUpdataDate(row) {
      if (row) {
        this.listQuery.updatedAtStart = this.formatUTCDate(this.updateDate[0]);
        this.listQuery.updatedAtEnd = this.formatUTCDate(this.updateDate[1]);
      } else {
        this.listQuery.updatedAtStart = null;
        this.listQuery.updatedAtEnd = null;
      }
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
      console.log(99);
    },
    getList: function getList() {
      var _this = this;

      var params = {
        account: this.listQuery.account ? this.listQuery.account : null,
        createdAtStart: this.listQuery.createdAtStart ? this.listQuery.createdAtStart : null,
        createdAtEnd: this.listQuery.createdAtEnd ? this.listQuery.createdAtEnd : null,
        status: this.listQuery.status ? this.listQuery.status : null,
        payeeAccount: this.listQuery.payeeAccount ? this.listQuery.payeeAccount : null,
        payeeName: this.listQuery.payeeName ? this.listQuery.payeeName : null,
        email: this.listQuery.email ? this.listQuery.email : null,
        updatedAtStart: this.listQuery.updatedAtStart ? this.listQuery.updatedAtStart : null,
        updatedAtEnd: this.listQuery.updatedAtEnd ? this.listQuery.updatedAtEnd : null,
        '$skip': (this.page - 1) * this.limit,
        '$limit': this.limit
      };
      this.listLoading = true;
      console.log(params);

      _api.default.getWithdrawals(params).then(function (res) {
        _this.list = res.data;
        _this.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.ids = val.map(function (item) {
        return item._id;
      });
      console.log(this.ids);
    },
    handleDispensing: function handleDispensing(row) {
      var _this2 = this;

      var params = {
        'status': 'approved',
        idUser: row.idUser,
        'idWithdrawal': row._id
      };
      this.$confirm('系统将给用户出款，此操作不可逆转, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.handleChangeWithdrawal(params);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消出款'
        });
      });
    },
    handleRefund: function handleRefund(row) {
      var _this3 = this;

      var params = {
        'status': 'rejected',
        idUser: row.idUser,
        'idWithdrawal': row._id
      };
      this.$confirm('系统将驳回此次提现申请，此操作不可逆转, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.handleChangeWithdrawal(params);
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleChangeWithdrawal: function handleChangeWithdrawal(params) {
      var _this4 = this;

      _api.default.updateWithdrawal(params).then(function (res) {
        _this4.getList();
      }).catch(function (e) {
        _this4.getList();
      });
    },
    resetForm: function resetForm() {
      this.listQuery = this.$_.clone(listQuery);
      this.date = null;
      this.updateDate = null;
      this.handleFilter();
    }
  }
};
exports.default = _default;