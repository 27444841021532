var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_c("count-to", _vm._b({}, "count-to", _vm.$attrs, false))],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }