"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _config = _interopRequireDefault(require("@/config/config"));

var _table = _interopRequireDefault(require("@/mixin/table"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var listQuery = {
  keyword: undefined,
  country: undefined,
  date: undefined,
  createdAt: undefined,
  areaOptions: [],
  industriesOptions: []
};
var _default = {
  name: 'BrandList',
  mixins: [_table.default],
  data: function data() {
    return {
      listQuery: this.$_.cloneDeep(listQuery),
      brandTypeOptions: _config.default.brandTypeOptions,
      areaOptions: this.$storage.get('areaOptions'),
      industriesOptions: this.$storage.get('industriesOptions')
    };
  },
  computed: {
    showPage: function showPage() {
      return this.$route.name === 'BrandList';
    }
  },
  created: function created() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      if (this.showPage) {
        this.getList();
      }
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        keyword: this.listQuery.keyword || undefined,
        '$skip': (this.page - 1) * this.limit,
        '$limit': this.limit
      });
      delete params.date;

      _api.default.fetchBrandList(params).then(function (res) {
        _this.listLoading = false;
        _this.list = res.data;
        _this.total = res.total;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleDate: function handleDate(data) {
      if (Array.isArray(data) && data.every(function (item) {
        return item instanceof Date;
      })) {
        this.listQuery.createdAt = {
          $gt: this.formatUtcDate(data[0]),
          $lt: this.formatUtcDate(data[1])
        };
      } else {
        this.listQuery.createdAt = undefined;
      }
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    handleViewKOL: function handleViewKOL(row) {
      this.$storage.session.set('brandBaseInfo', row);
      this.$router.push({
        name: 'BrandDetail',
        params: {
          id: row._id
        }
      });
    },
    resetForm: function resetForm() {
      this.listQuery = this.$_.cloneDeep(listQuery);
      this.handleFilter();
    }
  }
};
exports.default = _default;