var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "base-wrap"
    },
    [
      _c("h3", [_vm._v("基础信息")]),
      _vm._v(" "),
      _c("section", { staticClass: "base-info" }, [
        _c("div", { staticClass: "item-wrap" }, [
          _c("span", [_vm._v("头像")]),
          _vm._v(" "),
          _c(
            "span",
            [
              _c("el-avatar", {
                attrs: { src: _vm.profile.navAvatar, size: 100 }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-wrap" }, [
          _c("span", [_vm._v("Navigate ID")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.profile._id))])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-wrap" }, [
          _c("span", [_vm._v("KOL昵称")]),
          _vm._v(" "),
          _c("span", [
            _vm._v("baseInfo\n        "),
            _vm.profile.navDisplayName
              ? _c("span", [_vm._v(_vm._s(_vm.profile.navDisplayName))])
              : _c("span", [_vm._v("未填写")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-wrap" }, [
          _c("span", [_vm._v("性别")]),
          _vm._v(" "),
          _vm.profile.navGender
            ? _c("span", [
                _vm._v(_vm._s(_vm._f("statusSex")(_vm.profile.navGender)))
              ])
            : _c("span", [_vm._v("未填写")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-wrap" }, [
          _c("span", [_vm._v("联系邮箱")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.profile.navEmail))])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-wrap" }, [
          _c("span", [_vm._v("联系电话")]),
          _vm._v(" "),
          _vm.profile.navMobile
            ? _c("span", [_vm._v(_vm._s(_vm.profile.navMobile))])
            : _c("span", [_vm._v("未填写")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-wrap" }, [
          _c("span", [_vm._v("区域")]),
          _vm._v(" "),
          _vm.profile.area
            ? _c("span", [_vm._v(_vm._s(_vm.profile.area))])
            : _c("span", [_vm._v("--")])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-wrap" },
          [
            _c("span", [_vm._v("所属行业")]),
            _vm._v(" "),
            _vm._l(_vm.profile.industries, function(industry) {
              return _c("el-tag", { key: industry }, [
                _vm._v("\n        " + _vm._s(industry) + "\n      ")
              ])
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "item-wrap" }, [
          _c("span", [_vm._v("个性标签")]),
          _vm._v(" "),
          _c("span", [
            _vm.baseInfo.tags && _vm.baseInfo.tags.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.baseInfo.tags, function(tag) {
                    return _c("el-tag", { key: tag, staticClass: "mr-8" }, [
                      _vm._v(_vm._s(tag))
                    ])
                  }),
                  1
                )
              : _c("span", [_vm._v("未填写")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-wrap" }, [
          _c("span", [_vm._v("已绑定平台")]),
          _vm._v(" "),
          _vm.showPlatform
            ? _c(
                "span",
                { staticClass: "platform-wrap" },
                _vm._l(_vm.platforms, function(platform) {
                  return _c("svg-icon", {
                    key: platform.platform,
                    staticClass: "mr-8",
                    attrs: { "icon-class": platform.platform }
                  })
                }),
                1
              )
            : _c("span", [_vm._v("未绑定")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-wrap" }, [
          _c("span", [_vm._v("推荐人")]),
          _vm._v(" "),
          _vm.baseInfo.referrer !== "none"
            ? _c("span", [_vm._v(_vm._s(_vm.baseInfo.referrer))])
            : _c("span", [_vm._v("--")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-wrap" }, [
          _c("span", [_vm._v("注册时间")]),
          _vm._v(" "),
          _c("span", [
            _vm.profile
              ? _c("span", [_vm._v(_vm._s(_vm.fiterCreatedAt))])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("h3", [_vm._v("KOL成长信息")]),
      _vm._v(" "),
      _c("section", { staticClass: "growth-wrap" }, [
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("tip-msg", {
              staticClass: "tip",
              attrs: { content: "KOL入驻平台的粉丝数之和", size: 18 }
            }),
            _vm._v(" "),
            _vm.profile.fans
              ? _c("div", { staticClass: "top" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.profile.fans.toLocaleString()))
                  ])
                ])
              : _c("div", { staticClass: "top" }, [_c("span", [_vm._v("--")])]),
            _vm._v(" "),
            _c("div", { staticClass: "bottom text-theme" }, [
              _vm._v("总粉丝数")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("tip-msg", {
              staticClass: "tip",
              attrs: {
                content:
                  "Navigate平台根据KOL的影响力、推文媒体价值、活跃度、发文频率、互动情况、品牌合作度等因素综合计算而来，为0～1000的数值。"
              }
            }),
            _vm._v(" "),
            _vm.profile.platformScore
              ? _c("div", { staticClass: "top" }, [
                  _c("span", [
                    _vm._v(_vm._s(Math.round(_vm.profile.platformScore)))
                  ])
                ])
              : _c("div", { staticClass: "top" }, [_c("span", [_vm._v("--")])]),
            _vm._v(" "),
            _c("div", { staticClass: "bottom text-theme" }, [
              _vm._v("2dmScore")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("tip-msg", {
              staticClass: "tip",
              attrs: {
                content:
                  "Navigate平台根据KOL的粉丝触达数量、推文粉丝互动情况评估的KOL影响力，分为S、A、B、C、D 5个等级。"
              }
            }),
            _vm._v(" "),
            _vm.profile
              ? _c("div", { staticClass: "top" }, [
                  _c("span", [_vm._v(_vm._s(_vm.profile.influence || 0))])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "bottom text-theme" }, [_vm._v("影响力")])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("tip-msg", {
              staticClass: "tip",
              attrs: {
                content:
                  "Navigate平台根据KOL在其社交媒体平台上发帖数、发文频率、粉丝互动情况的评估活跃度。",
                size: 18
              }
            }),
            _vm._v(" "),
            _vm.profile.activity
              ? _c("div", { staticClass: "top" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentageFormatter")(
                          _vm.profile.activity / 100
                        )
                      )
                    )
                  ])
                ])
              : _c("div", { staticClass: "top" }, [_c("span", [_vm._v("--")])]),
            _vm._v(" "),
            _c("div", { staticClass: "bottom text-theme" }, [_vm._v("活跃度")])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }