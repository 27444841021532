"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _echarts = _interopRequireDefault(require("@/utils/echarts"));

var _resize = _interopRequireDefault(require("../mixins/resize"));

//
//
//
var listColor = ['#2ec7c9', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80', '#8d98b3', '#e5cf0d', '#97b552', '#95706d', '#dc69aa', '#07a2a4', '#9a7fd1', '#588dd5', '#f5994e', '#c05050', '#59678c', '#c9ab00', '#7eb00a', '#6f5553', '#c14089'];
var _default2 = {
  name: 'WordCloud',
  mixins: [_resize.default],
  props: {
    chartData: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  },
  watch: {
    chartData: function chartData(data) {
      this.filterData();
    }
  },
  mounted: function mounted() {
    this.filterData();
  },
  methods: {
    filterData: function filterData() {
      var option = {
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        },
        series: [{
          type: 'wordCloud',
          gridSize: 16,
          sizeRange: [12, 36],
          rotationRange: [-45, 45],
          shape: 'circle',
          width: '100%',
          height: '90%',
          drawOutOfBound: false,
          cursor: 'cross',
          textStyle: {
            normal: {
              color: function color() {
                var index = Math.floor(Math.random() * 20);
                return listColor[index];
              }
            },
            emphasis: {
              shadowBlur: 2,
              shadowColor: '#eee'
            }
          },
          data: []
        }]
      };
      option.series[0].data = this.chartData.data;
      this.draw(option);
    },
    draw: function draw(option) {
      // 实例化echarts对象
      this.$nextTick(function () {
        var myChart = _echarts.default.init(this.$refs.WordCloud);

        myChart.setOption(option);
      });
    }
  }
};
exports.default = _default2;