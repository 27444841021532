"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _config = _interopRequireDefault(require("@/config/config"));

var _api = _interopRequireDefault(require("@/api"));

var tabel = {
  data: function data() {
    return {
      list: [],
      total: 0,
      listLoading: false,
      page: 1,
      limit: 20,
      pickerOptions: _config.default.pickerOptions,
      areaOptions: [],
      platformOptions: [],
      industriesOptions: [],
      influenceOptions: [],
      followStatusOptions: [],
      sourceOptions: [],
      priorityOptions: [],
      referrerOptions: []
    };
  },
  methods: {
    isGetFieldsManager: function isGetFieldsManager() {
      var options = this.$storage.session.get('options');
      this.areaOptions = options.area.data;
      this.platformOptions = options.platform.data;
      this.industriesOptions = options.industry.data;
      this.sourceOptions = options.sourceOptions.data;
      this.followStatusOptions = options.followStatusOptions.data;
      this.priorityOptions = options.priorityOptions.data;
      this.influenceOptions = options.influence.data;

      if (this.areaOptions && this.areaOptions.length <= 0) {
        this.getFieldsManager();
      }
    },
    getFieldsManager: function getFieldsManager() {
      var _this = this;

      this.referrerOptions = this.$storage.session.get('referrerOptions');

      _api.default.getFieldsManager().then(function (res) {
        _this.$storage.set('areaOptions', res.areaAll.data);

        _this.$storage.set('industriesOptions', res.industryAll.data);

        _this.$storage.set('platformOptions', res.platformAll.data);

        _this.$storage.set('influenceOptions', res.influenceAll.data);

        _this.$storage.set('fansOptions', res.fans.data);
      });
    },
    getReferrers: function getReferrers() {
      var _this2 = this;

      _api.default.getReferrers().then(function (res) {
        !Array.isArray(res) ? _this2.$storage.session.set('referrerOptions'.res.data) : _this2.$storage.session.set([]);
      });
    },
    formatUTCDate: function formatUTCDate(utc_datetime) {
      var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD';
      return this.$moment(utc_datetime).format(fmt);
    },
    comIndustries: function comIndustries(industries) {
      var _this3 = this;

      var comArr = [];

      if (industries instanceof Array) {
        industries.forEach(function (item) {
          console.log(_this3.industriesOptions, 999999);

          _this3.industriesOptions.forEach(function (ele) {
            if (ele.value === item) {
              comArr.push(ele.name);
            }
          });
        });
      } else {
        this.industriesOptions.forEach(function (ele) {
          if (ele.value === industries) {
            comArr.push(ele.name);
          }
        });
      }

      return comArr;
    },
    comArea: function comArea(code) {
      var areaLabel = '';
      this.areaOptions.forEach(function (item) {
        if (code === item.value) {
          areaLabel = item.label;
        }
      });
      return areaLabel;
    },
    comSource: function comSource(source) {
      var sourceLabel = {};

      switch (source) {
        case '0':
          sourceLabel.type = 'success';
          break;

        case '1':
          sourceLabel.type = 'warning';
          break;

        default:
          sourceLabel.type = 'danger';
          break;
      }

      this.sourceOptions.forEach(function (item) {
        if (source === item.value) {
          sourceLabel.name = item.name;
        }
      });
      return sourceLabel;
    },
    comAreaDeep: function comAreaDeep(areaArr) {
      var areaLabel = [];

      if (areaArr && areaArr.length > 0) {
        this.areaOptions.forEach(function (item) {
          if (item.value === areaArr[0]) {
            areaLabel.push(item.label);

            if (areaArr.length >= 2) {
              item.children.forEach(function (ele) {
                if (ele.value === areaArr[1]) {
                  areaLabel.push(ele.label);
                }
              });
            }
          }
        });
      }

      return areaLabel;
    },
    comReferrer: function comReferrer(referrer) {
      var referrerLabel = '';
      this.referrerOptions.forEach(function (item) {
        if (item._id === referrer) {
          referrerLabel = item.name;
        }
      });
      return referrerLabel;
    },
    comPriority: function comPriority(priority) {
      var mapArr = {
        0: 'danger',
        1: 'warning',
        2: 'success'
      };
      var priorityLabel = {};
      this.priorityOptions.forEach(function (item) {
        if (item.value === priority) {
          priorityLabel.name = item.name;
          priorityLabel.type = mapArr[item.value];
        }
      });
      return priorityLabel;
    },
    comFollowStatus: function comFollowStatus(status) {
      var mapArr = {
        0: 'danger',
        1: 'warning',
        2: '',
        3: 'info',
        4: 'success'
      };
      var statusLabel = {};
      this.followStatusOptions.forEach(function (item) {
        if (item.value === status) {
          statusLabel.name = item.name;
          statusLabel.type = mapArr[item.value];
        }
      });
      return statusLabel;
    },
    comCampaignsStatus: function comCampaignsStatus(status) {
      var statusMap = {
        pending: '',
        inProgress: 'success',
        finished: 'info',
        cancelled: 'danger',
        inApproval: 'warning'
      };
      return statusMap[status];
    },
    comStatusTag: function comStatusTag(status) {
      var statusMap = {
        pending: 'warning',
        inProgress: 'danger',
        draft: 'warning',
        finished: 'success',
        cancelled: 'info'
      };
      return statusMap[status];
    },
    formatUtcDate: function formatUtcDate(date) {
      return this.$moment(date).utc().format();
    },
    updateLimit: function updateLimit(val) {
      this.limit = val;
      this.handleFilter();
    }
  }
};
var _default = tabel;
exports.default = _default;