"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    title: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '1200px'
    },
    rowData: {
      type: Object,
      default: function _default() {}
    },
    configType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      visible: this.show
    };
  },
  computed: {
    computedTitle: function computedTitle() {
      if (this.configType) {
        var configType = '';

        if (this.configType === 'auto') {
          configType = this.$t('page.createCampaign.auto');
        } else {
          configType = this.$t('page.createCampaign.custom');
        }

        return this.$t('operation.previewCampaign') + configType;
      } else {
        return this.title;
      }
    }
  },
  watch: {
    show: function show() {
      this.visible = this.show;
    }
  }
};
exports.default = _default2;