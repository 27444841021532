"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
var _default = {
  props: {
    size: {
      type: [String, Number],
      default: '18'
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    width: {
      type: [Number, String],
      default: 200
    }
  }
};
exports.default = _default;