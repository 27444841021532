var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "edit-div",
    attrs: { contenteditable: _vm.canEdit },
    domProps: { innerHTML: _vm._s(_vm.innerText) },
    on: {
      focus: function($event) {
        _vm.isLocked = true
      },
      blur: function($event) {
        _vm.isLocked = false
      },
      input: _vm.changeText
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }