var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nodata-wrap", style: { minHeight: _vm.minHeight + "px" } },
    [
      _c(
        "div",
        { style: { fontSize: _vm.size + "px" } },
        [
          _c("svg-icon", { attrs: { "icon-class": _vm.imgSrc } }),
          _vm._v(" "),
          _c("p", { staticClass: "desc text-gray-700" }, [
            _vm._v(_vm._s(_vm.desc))
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }