"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _vue = _interopRequireDefault(require("vue"));

var _vuePreview = _interopRequireDefault(require("vue-preview"));

var _global = _interopRequireDefault(require("@/mixin/global"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
_vue.default.use(_vuePreview.default, {
  captionEl: false,
  fullscreenEl: false,
  shareEl: false
});

var _default = {
  name: 'CampaiginDatail',
  mixins: [_global.default],
  data: function data() {
    return {
      id: null,
      pageLoading: false,
      campaign: null,
      brandIntroLimit: 280,
      productDescLimit: 280,
      descLimit: 280,
      noteLimit: 280,
      areaAll: null,
      industryAll: null
    };
  },
  computed: {
    slide: function slide() {
      this.campaign.assets.imgs.map(function (item) {
        item.src = item.url;
        item.msrc = item.url;
        item.title = item.name || '';
        item.w = 1200;
        item.h = 760;
      });
      return this.campaign.assets.imgs;
    },
    slideMob: function slideMob() {
      return this.campaign.assets.imgs[0].src;
    },
    industryCon: function industryCon() {
      var _this = this;

      var str = '';
      this.campaign.industries.forEach(function (item) {
        str += _this.industryAll[item] + ' ';
      });
      return str;
    },
    areaCon: function areaCon() {
      var _this2 = this;

      var str = '';
      this.campaign.targetAreas.forEach(function (item) {
        str += _this2.areaAll[item] + ' ';
      });
      return str;
    }
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.fetchOptions();
    this.areaAll = JSON.parse(localStorage.getItem('areaOptions'));
    this.industryAll = JSON.parse(localStorage.getItem('industriesOptions'));
    this.getCampaign();
  },
  methods: {
    getCampaign: function getCampaign() {
      var _this3 = this;

      this.pageLoading = false;

      _api.default.getCampaign(this.id).then(function (res) {
        _this3.campaign = res;
        console.log(res);
      }).finally(function () {
        _this3.pageLoading = false;
      });
    }
  }
};
exports.default = _default;