"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CreateRoleDialog',
  props: {
    permissionsTree: {
      type: Array,
      default: undefined
    }
  },
  data: function data() {
    return {
      visible: true,
      dirty: false,
      loading: false,
      createRoleForm: {
        label: '',
        permissions: []
      },
      createRoleRules: {
        label: [{
          type: 'string',
          required: true,
          trigger: ['change', 'blur'],
          message: '请输入名称',
          transform: function transform(value) {
            return value.trim();
          }
        }],
        permissions: [{
          type: 'array',
          required: true,
          trigger: 'change',
          message: '请至少选择一个角色权限'
        }]
      }
    };
  },
  methods: {
    handleClose: function handleClose(done) {
      var _this = this;

      if (this.dirty) {
        this.$confirm('确定取消所做的更改么？', {
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        }).then(function () {
          _this.resetForm();

          _this.$emit('closeDialog');

          if (done) done();
        }).catch(function () {});
      } else {
        this.resetForm();
        this.$emit('closeDialog');
        if (done) done();
      }
    },
    handleInputChange: function handleInputChange() {
      this.dirty = true;
    },
    resetForm: function resetForm() {
      this.$refs.createRoleForm.resetFields();
      this.dirty = false;
    },
    handlePermissionsTreeChange: function handlePermissionsTreeChange() {
      this.dirty = true;
      this.createRoleForm.permissions = this.$refs.permissionsTree.getCheckedNodes().map(function (node) {
        return node._id;
      });
    },
    handleCreateRole: function handleCreateRole() {
      var _this2 = this;

      if (!this.dirty) {
        this.$emit('closeDialog');
        return true;
      }

      this.$refs.createRoleForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;

          _api.default.createRole({
            label: _this2.createRoleForm.label,
            permissions: _this2.createRoleForm.permissions
          }).then(function () {
            _this2.loading = false;

            _this2.resetForm();

            _this2.$emit('roleCreated');

            _this2.$emit('closeDialog');
          }).catch(function () {
            _this2.loading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;