var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "post-wrap" },
    _vm._l(_vm.column, function(item) {
      return _c("post-card", {
        key: item.link,
        attrs: {
          id: item.link,
          link: item.link,
          image: item.image ? item.image : _vm.defaultImg,
          "retweet-number": item.retweet,
          "comment-number": item.comment,
          "like-number": item.like,
          "platform-media-vale": item.platformMediaVale,
          desc: item.description,
          col: 4
        },
        on: { handleViewPostDetail: _vm.handleViewPostDetail }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }