"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.setToken = setToken;
exports.removeToken = removeToken;
exports.getUid = getUid;
exports.setUid = setUid;
exports.removeUid = removeUid;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var TokenKey = 'navigate-admin';
var Uid = '_id';

function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token, {
    expires: 7
  });
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}

function getUid() {
  return _jsCookie.default.get(Uid);
}

function setUid(uid) {
  return _jsCookie.default.set(Uid, uid);
}

function removeUid() {
  return _jsCookie.default.remove(Uid);
}