"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = require("@/utils");

var _default = {
  data: function data() {
    return {
      $_sidebarElm: null
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.__resizeHandler = (0, _utils.debounce)(function () {
      if (_this.chart) {
        _this.chart.resize();
      }
    }, 100);
    window.addEventListener('resize', this.__resizeHandler);
    this.$_sidebarElm = document.getElementsByClassName('sidebar-container')[0];
    this.$_sidebarElm && this.$_sidebarElm.addEventListener('transitionend', this.$_sidebarResizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.__resizeHandler);
    this.$_sidebarElm && this.$_sidebarElm.removeEventListener('transitionend', this.$_sidebarResizeHandler);
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_sidebarResizeHandler: function $_sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.__resizeHandler();
      }
    }
  }
};
exports.default = _default;