"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _vue = _interopRequireDefault(require("vue"));

var _lodashPlugin = _interopRequireDefault(require("./lodashPlugin"));

var _momentPlugin = _interopRequireDefault(require("./momentPlugin"));

var _storage = _interopRequireDefault(require("./storage"));

_vue.default.use(_lodashPlugin.default);

_vue.default.use(_momentPlugin.default);

_vue.default.use(_storage.default);