"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

// 文件路径 @/lib/echarts.js 自行配置
// 引入 ECharts 主模块
var echarts = require('echarts/lib/echarts');

require('echarts/lib/chart/bar');

require('echarts/lib/chart/line');

require('echarts/lib/chart/scatter');

require('echarts/lib/chart/pie'); // 引入提示框和标题组件


require('echarts/lib/component/tooltip');

require('echarts/lib/component/title');

require('echarts-wordcloud');

require('echarts/lib/component/legend');

require('echarts/lib/component/legend');

require('echarts/lib/component/dataZoom');

var _default = echarts;
exports.default = _default;