exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".edit-div{width:100%;height:100%;overflow:auto;word-break:break-all;outline:none;-webkit-user-select:text;-moz-user-select:text;-ms-user-select:text;user-select:text;white-space:pre-wrap;text-align:left}.edit-div[contenteditable=true]{user-modify:read-write-plaintext-only}.edit-div[contenteditable=true]:empty:before{content:attr(placeholder);color:#ccc}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "210px"
};