"use strict";

var _interopRequireWildcard = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var kol = _interopRequireWildcard(require("./kol"));

var common = _interopRequireWildcard(require("./common"));

var user = _interopRequireWildcard(require("./user"));

var role = _interopRequireWildcard(require("./role"));

var brand = _interopRequireWildcard(require("./brand"));

var campaigns = _interopRequireWildcard(require("./campaigns"));

var supportTicket = _interopRequireWildcard(require("./support-tickets"));

var permission = _interopRequireWildcard(require("./permission"));

var API = (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, kol), common), role), brand), campaigns), user), supportTicket), permission);
var _default = API;
exports.default = _default;