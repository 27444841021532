"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _EditDiv = _interopRequireDefault(require("./EditDiv"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    EditDiv: _EditDiv.default
  },
  props: {
    emailObject: {
      type: Object,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      type: 'brandCN',
      prodectIntro: '',
      logoImg: require('@/assets/images/common/logo.png')
    };
  },
  methods: {
    sendEmail: function sendEmail() {
      this.$emit('sendEmail');
    },
    updateBody: function updateBody(val) {
      this.emailObject.body = val;
    },
    changeText: function changeText() {
      this.innerText = this.$el.innerHTML;
      this.$emit('input', this.innerText);
    }
  }
};
exports.default = _default2;