"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _BaseInfo = _interopRequireDefault(require("./components/BaseInfo"));

var _PlatformInfo = _interopRequireDefault(require("./components/PlatformInfo"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'KolDetail',
  components: {
    BaseInfo: _BaseInfo.default,
    PlatformInfo: _PlatformInfo.default
  },
  data: function data() {
    return {
      id: '',
      activeName: 'baseInfo'
    };
  }
};
exports.default = _default;