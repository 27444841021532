var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tip-msg" },
    [
      _c(
        "el-popover",
        _vm._g(
          _vm._b(
            { attrs: { trigger: _vm.trigger, width: _vm.width } },
            "el-popover",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c("svg-icon", {
            staticClass: "qa-icon",
            style: { fontSize: _vm.size + "px" },
            attrs: { slot: "reference", "icon-class": "tip" },
            slot: "reference"
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }