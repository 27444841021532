"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.link");

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _vue = _interopRequireDefault(require("vue"));

var _vueTelInput = _interopRequireDefault(require("vue-tel-input"));

var _api = _interopRequireDefault(require("@/api"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
_vue.default.use(_vueTelInput.default);

var _default = {
  data: function data() {
    return {
      id: '',
      showPlatformErrolTip: false,
      loading: false,
      ruleForm: {
        displayName: '',
        source: '',
        priority: '',
        email: '',
        referral: '',
        contactPhone: '',
        area: '',
        industries: null,
        platforms: [{
          platform: '',
          link: ''
        }],
        status: '',
        remark: '',
        updateAt: '',
        operator: {
          id: this.uid,
          disPlayName: this.name
        }
      },
      rules: {
        displayName: [{
          required: true,
          message: '请填写昵称',
          trigger: 'change'
        }],
        source: [{
          required: true,
          message: '选择来源渠道',
          trigger: 'change'
        }],
        priority: [{
          required: true,
          message: '选择优先级',
          trigger: 'change'
        }]
      },
      sourceOptions: [],
      priorityOptions: [],
      areaOptions: [],
      industriesOptions: [],
      platformOptions: [],
      referrerOptions: [],
      followStatusOptions: []
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['uid', 'name'])), {}, {
    filterUpdate: function filterUpdate() {
      return this.ruleForm.updateAt ? this.$moment.utc(this.ruleForm.updateAt).format('YYYY-MM-DD hh:mm:ss') : '';
    }
  }),
  created: function created() {
    this.editState();
    this.getFieldsManager();
  },
  methods: {
    editState: function editState() {
      this.id = this.$route.params.id;
      this.$route.name === 'EditKol' ? this.getKOLInfo() : '';
    },
    getKOLInfo: function getKOLInfo() {
      var _this = this;

      _api.default.getKOLInfo(this.id).then(function (res) {
        console.log('res-info :', res);
        _this.ruleForm = res;
      });
    },
    getFieldsManager: function getFieldsManager() {
      var options = this.$storage.session.get('options');
      this.sourceOptions = options.sourceOptions.data;
      this.priorityOptions = options.priorityOptions.data;
      this.areaOptions = options.area.data;
      this.platformOptions = options.platform.data.filter(function (item) {
        return item.name !== '全部';
      });
      this.industriesOptions = options.industry.data.filter(function (item) {
        return item.name !== '全部';
      });
      this.followStatusOptions = options.followStatusOptions.data;
      this.referrerOptions = this.$storage.session.get('referrerOptions').filter(function (item) {
        return item.name !== '全部';
      });
    },
    changeInput: function changeInput(number, isValid, country) {
      this.ruleForm.contactPhone = isValid.number.e164;
    },
    handleChangePlatform: function handleChangePlatform(index, platform) {
      this.ruleForm.platforms[index].platform = platform;
    },
    handleChangeLink: function handleChangeLink(index, link) {
      this.ruleForm.platforms[index].link = link;
    },
    addPlatform: function addPlatform() {
      if (this.ruleForm.platforms.length === this.platformOptions.length) {
        this.$message.warning("\u6700\u591A\u53EA\u80FD\u6DFB\u52A0".concat(this.platformOptions.length, "\u4E2A\u5E73\u53F0"));
      } else {
        this.ruleForm.platforms.push({
          platform: '',
          link: ''
        });
        this.showPlatformErrolTip = false;
      }
    },
    uniqPlatforms: function uniqPlatforms(arr) {
      var data = this.$_.unionBy(this.ruleForm.platforms, 'platform');
      return data;
    },
    validatePlatform: function validatePlatform() {
      var results = this.ruleForm.platforms.some(function (item) {
        return !!item.link;
      });

      if (!results) {
        this.showPlatformErrolTip = true;
      } else {
        this.showPlatformErrolTip = false;
      }
    },
    handleSave: function handleSave(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        _this2.validatePlatform();

        if (valid) {
          _this2.ruleForm.platforms = _this2.uniqPlatforms(_this2.ruleForm.platforms);
          var routeName = _this2.$route.name;

          if (!_this2.showPlatformErrolTip) {
            var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this2.ruleForm), {}, {
              operator: {
                id: _this2.uid,
                disPlayName: _this2.name
              }
            });
            delete data.updateAt;
            _this2.loading = true;

            if (routeName === 'EditKol') {
              _api.default.patchKOLInfo(_this2.id, data).then(function (res) {
                console.log('res-save :', res);
                _this2.loading = false;

                _this2.$router.push({
                  name: 'KolFollowUp'
                });
              }).catch(function () {
                _this2.loading = false;
              });
            } else {
              _api.default.postKOLInfo(data).then(function (res) {
                _this2.loading = false;
                console.log('post-save :', res);

                _this2.$router.push({
                  name: 'KolFollowUp'
                });
              }).catch(function () {
                _this2.loading = false;
              });
            }
          }
        }
      });
    }
  }
};
exports.default = _default;