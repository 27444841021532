var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-kol" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "auto"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "KOL昵称", prop: "displayName" } },
            [
              _c("el-input", {
                staticClass: "form-width-500",
                attrs: { placeholder: "填写kol昵称" },
                model: {
                  value: _vm.ruleForm.displayName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "displayName", $$v)
                  },
                  expression: "ruleForm.displayName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "来源渠道", prop: "source" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form-width-500",
                  attrs: { placeholder: "优先级来源渠道" },
                  model: {
                    value: _vm.ruleForm.source,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "source", $$v)
                    },
                    expression: "ruleForm.source"
                  }
                },
                _vm._l(_vm.sourceOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "优先级", prop: "priority" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form-width-500",
                  attrs: { placeholder: "选择优先级" },
                  model: {
                    value: _vm.ruleForm.priority,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "priority", $$v)
                    },
                    expression: "ruleForm.priority"
                  }
                },
                _vm._l(_vm.priorityOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "联系邮箱", prop: "email" } },
            [
              _c("el-input", {
                staticClass: "form-width-500",
                model: {
                  value: _vm.ruleForm.email,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "email", $$v)
                  },
                  expression: "ruleForm.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "联系电话" } }, [
            _c(
              "div",
              {
                staticClass: "el-input el-input--medium form-width-500 vue-tel"
              },
              [
                _c("vue-tel-input", {
                  staticClass: "el-input__inner",
                  attrs: { placeholder: "电话号码" },
                  on: { input: _vm.changeInput },
                  model: {
                    value: _vm.ruleForm.contactPhone,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "contactPhone", $$v)
                    },
                    expression: "ruleForm.contactPhone"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "区域", prop: "area" } },
            [
              _c("el-cascader", {
                staticClass: "form-width-500",
                attrs: {
                  "change-on-select": "true",
                  placeholder: "请选择区域",
                  options: _vm.areaOptions
                },
                model: {
                  value: _vm.ruleForm.area,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "area", $$v)
                  },
                  expression: "ruleForm.area"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所属行业", prop: "industries" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form-width-500",
                  attrs: {
                    placeholder: "选择所属行业",
                    "multiple-limit": 3,
                    multiple: ""
                  },
                  model: {
                    value: _vm.ruleForm.industries,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "industries", $$v)
                    },
                    expression: "ruleForm.industries"
                  }
                },
                _vm._l(_vm.industriesOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "社交媒体平台", required: "" } },
            [
              _c(
                "div",
                { staticClass: "form-width-500" },
                [
                  _vm._l(_vm.ruleForm.platforms, function(item, index) {
                    return _c(
                      "el-input",
                      {
                        key: index,
                        staticClass: "platform mt-20",
                        attrs: { placeholder: "请输入对应社交媒体主页链接" },
                        on: {
                          change: function($event) {
                            return _vm.handleChangeLink(index, item.link)
                          },
                          blur: _vm.validatePlatform
                        },
                        model: {
                          value: item.link,
                          callback: function($$v) {
                            _vm.$set(item, "link", $$v)
                          },
                          expression: "item.link"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "form-country",
                            staticStyle: { width: "100px" },
                            attrs: { slot: "prepend", placeholder: "" },
                            on: {
                              change: function($event) {
                                return _vm.handleChangePlatform(
                                  index,
                                  item.platform
                                )
                              },
                              blur: _vm.validatePlatform
                            },
                            slot: "prepend",
                            model: {
                              value: item.platform,
                              callback: function($$v) {
                                _vm.$set(item, "platform", $$v)
                              },
                              expression: "item.platform"
                            }
                          },
                          _vm._l(_vm.platformOptions, function(i) {
                            return _c("el-option", {
                              key: i.value,
                              attrs: { label: i.name, value: i.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showPlatformErrolTip,
                          expression: "showPlatformErrolTip"
                        }
                      ],
                      staticClass: "el-form-item__error"
                    },
                    [_vm._v("请选择社交平台并输入对应社交媒体主页链接")]
                  )
                ],
                2
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addPlatform } },
                [_vm._v("增加")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "跟进人", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form-width-500",
                  attrs: { placeholder: "跟进人" },
                  model: {
                    value: _vm.ruleForm.referral,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "referral", $$v)
                    },
                    expression: "ruleForm.referral"
                  }
                },
                _vm._l(_vm.referrerOptions, function(item) {
                  return _c("el-option", {
                    key: item._id,
                    attrs: { label: item.displayName, value: item._id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "跟进状态", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form-width-500",
                  attrs: { placeholder: "选择跟进状态" },
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status"
                  }
                },
                _vm._l(_vm.followStatusOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                staticClass: "form-width-500",
                attrs: { type: "textarea", autosize: { mix: 4, max: 10 } },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "更新时间", prop: "updateAt", disabled: "" } },
            [
              _c("el-input", {
                staticClass: "form-width-500",
                attrs: { disabled: "" },
                model: {
                  value: _vm.filterUpdate,
                  callback: function($$v) {
                    _vm.filterUpdate = $$v
                  },
                  expression: "filterUpdate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "操作人", prop: "operator" } },
            [
              _c("el-input", {
                staticClass: "form-width-500",
                attrs: { disabled: "" },
                model: {
                  value: _vm.ruleForm.operator.disPlayName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm.operator, "disPlayName", $$v)
                  },
                  expression: "ruleForm.operator.disPlayName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form-width-500" },
            [
              _c(
                "el-button",
                {
                  staticClass: "form-width-500",
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSave("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }