"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Config = {
  baseUrl: process.env.VUE_APP_BASE_URL,
  pickerOptions: {
    shortcuts: [{
      text: '最近一周',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一个月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近三个月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  },
  timeOptions: [{
    value: 3,
    name: '3个月'
  }, {
    value: 6,
    name: '6个月'
  }, {
    value: 12,
    name: '12个月'
  }],
  statistics: {
    first: {
      name: '进行中',
      icon: 'inProgress',
      num: 0
    },
    second: {
      name: '已完成',
      icon: 'finished',
      num: 0
    },
    third: {
      name: '已失效',
      icon: 'cancelled',
      num: 0
    }
  },
  campaignOptions: [{
    name: '植入',
    value: 'banner'
  }, {
    name: '定制',
    value: 'custom'
  }],
  statusOptions: [{
    name: '草稿',
    value: 'draft'
  }, {
    name: '审核中',
    value: 'inApproval'
  }, {
    name: '审核驳回',
    value: 'rejected'
  }, {
    name: '招募中',
    value: 'inProgress'
  }, {
    name: '已结束',
    value: 'finished'
  }, {
    name: '招募暂停',
    value: 'cancelled'
  }],
  brandTypeOptions: [{
    name: '广告代理',
    value: 'agency'
  }, {
    name: '品牌方',
    value: 'brand'
  }],
  transactionOptions: [{
    value: 'all',
    label: '全部'
  }, {
    value: 'credit',
    label: '充值'
  }, {
    value: 'debit',
    label: '扣款'
  }]
};
var _default = Config;
exports.default = _default;