"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _global = _interopRequireDefault(require("@/mixin/global"));

//
//
//
var _default = {
  name: 'Analysis',
  mixins: [_global.default],
  created: function created() {
    this.getOptions();
  },
  methods: {
    getOptions: function getOptions() {
      var referrerOptions = this.$storage.session.get('referrerOptions');
      var options = this.$storage.session.get('options');
      var hasOptions = referrerOptions && referrerOptions.length > 0 && options && options.length > 0;

      if (!hasOptions) {
        this.fetchOptions();
        this.fetchReferrers();
      }
    }
  }
};
exports.default = _default;