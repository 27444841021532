"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.number.constructor");

var _api = _interopRequireDefault(require("@/api"));

var _table = _interopRequireDefault(require("@/mixin/table"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var listQuery = {
  keyword: undefined,
  date: [],
  area: undefined,
  platforms: undefined,
  industries: undefined,
  fans: {
    $gt: undefined,
    $lt: undefined
  },
  claimedOn: undefined
};
var _default = {
  name: 'KolIntake',
  mixins: [_table.default],
  data: function data() {
    return {
      listQuery: this.$_.cloneDeep(listQuery),
      statistics: undefined,
      areaOptions: this.$storage.get('areaOptions'),
      industriesOptions: this.$storage.get('industriesOptions'),
      platformOptions: this.$storage.get('platformOptions'),
      fansLimit: this.$storage.get('fansOptions').reduce(function (num, fans) {
        // Get the highest fans limit
        var value = Number(fans.value.split('-').pop());
        return value > num ? value : num;
      }, 0),
      tableData: [{
        date: '2016-05-03',
        name: 'Tom',
        address: 'No. 189, Grove St, Los Angeles',
        fans: 1
      }, {
        date: '2016-05-02',
        name: 'Tom',
        address: 'No. 189, Grove St, Los Angeles',
        fans: 2
      }, {
        date: '2016-05-04',
        name: 'Tom',
        address: 'No. 189, Grove St, Los Angeles',
        fans: 3
      }, {
        date: '2016-05-01',
        name: 'Tom',
        address: 'No. 189, Grove St, Los Angeles',
        fans: 4
      }]
    };
  },
  computed: {
    showPage: function showPage() {
      return this.$route.name === 'KolIntake';
    }
  },
  created: function created() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      if (this.showPage) {
        this.getList();
        this.getInfluencersStatistics();
      }
    },
    getInfluencersStatistics: function getInfluencersStatistics() {
      var _this = this;

      _api.default.influencersStatus().then(function (res) {
        _this.statistics = {
          first: {
            name: '红人总数',
            num: res.total,
            icon: 'wallet'
          },
          second: {
            name: '今日新增',
            num: res.day,
            icon: 'wallet'
          },
          third: {
            name: '本月新增',
            num: res.month,
            icon: 'wallet'
          }
        };
      });
    },
    getList: function getList() {
      var _this2 = this;

      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        keyword: this.listQuery.keyword || undefined,
        fans: {
          $gt: this.listQuery.fans.$gt,
          $lt: this.listQuery.fans.$lt
        },
        claimed: 1,
        '$skip': (this.page - 1) * this.limit,
        '$limit': this.limit
      });
      params = this.$_.pickBy(params, this.$_.identity);
      delete params.date;
      this.listLoading = true;

      _api.default.fetchList(params).then(function (res) {
        _this2.list = res.data;
        _this2.total = res.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    filterPlatforms: function filterPlatforms(object) {
      var platforms = [];

      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          if (object[key] !== null) {
            platforms.push({
              kolID: object[key],
              platforms: key
            });
          }
        }
      }

      return platforms;
    },
    checkMax: function checkMax(val) {
      if (!this.listQuery.fans['$lt']) {
        this.listQuery.fans['$lt'] = val;
      }

      if (this.listQuery.fans['$lt'] < val) {
        this.listQuery.fans['$lt'] = val;
      }
    },
    handleFilter: function handleFilter() {
      if (this.listQuery.fans && this.listQuery.fans['$lt'] < this.listQuery['$gt']) {
        this.$message.warning("\u6700\u5C0F2dmScore\u4E0D\u80FD\u6BD4\u6700\u59272dmScore\u5927");
      }

      this.page = 1;
      this.getList();
    },
    handleDate: function handleDate(row) {
      this.listQuery.claimedOn = {
        $gt: this.listQuery.date[0],
        $lt: this.listQuery.date[1]
      };
    },
    handleViewKOLDetail: function handleViewKOLDetail(row) {
      this.$router.push({
        name: 'KolDetail',
        params: {
          id: row._id
        }
      });
      this.$storage.session.set('profile', row);
    },
    resetForm: function resetForm() {
      this.listQuery = this.$_.cloneDeep(listQuery);
      this.handleFilter();
    },
    getPlatformLink: function getPlatformLink(row, platform) {
      return row.platformKol.filter(function (item) {
        return item.platform === platform;
      }).shift().platformLink;
    }
  }
};
exports.default = _default;