var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._b(
      {
        attrs: {
          title: _vm.computedTitle,
          visible: _vm.visible,
          show: _vm.show,
          width: _vm.width
        },
        on: {
          "update:visible": function($event) {
            _vm.visible = $event
          },
          close: function($event) {
            return _vm.$emit("update:show", false)
          }
        }
      },
      "el-dialog",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }