"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _api = _interopRequireDefault(require("@/api"));

var _config = _interopRequireDefault(require("@/config/config"));

var _table = _interopRequireDefault(require("@/mixin/table"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var listQuery = {
  createdAtDate: undefined,
  updatedAtDate: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  status: undefined,
  provider: undefined,
  'idUser.email': undefined,
  'idUser.name': undefined
};
var _default = {
  name: 'Recharges',
  mixins: [_table.default],
  data: function data() {
    return {
      listQuery: this.$_.clone(listQuery),
      page: 1,
      limit: 10,
      total: 0,
      statusOptions: [{
        name: '成功',
        value: 'succeeded'
      }, {
        name: '待支付',
        value: 'pending'
      }, {
        name: '失败',
        value: 'failed'
      }],
      list: [],
      listLoading: false,
      pickerOptions: _config.default.pickerOptions,
      date: null,
      updateDate: null,
      ids: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    translateProvider: function translateProvider(provider) {
      var providerMap = {
        alipay: '支付宝',
        stripe: 'Stripe'
      };
      return providerMap[provider];
    },
    translateStatus: function translateStatus(status) {
      var statusMap = {
        pending: '待支付',
        succeeded: '成功',
        failed: '失败'
      };
      return statusMap[status];
    },
    handleCreatedAtDate: function handleCreatedAtDate(data) {
      if (Array.isArray(data) && data.every(function (item) {
        return item instanceof Date;
      })) {
        this.listQuery.createdAt = {
          $gt: this.formatUtcDate(data[0]),
          $lt: this.formatUtcDate(data[1])
        };
      } else {
        this.listQuery.createdAt = undefined;
      }
    },
    handleUpdatedAtDate: function handleUpdatedAtDate(data) {
      if (Array.isArray(data) && data.every(function (item) {
        return item instanceof Date;
      })) {
        this.listQuery.updatedAt = {
          $gt: this.formatUtcDate(data[0]),
          $lt: this.formatUtcDate(data[1])
        };
      } else {
        this.listQuery.updatedAt = undefined;
      }
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        type: 'credit',
        'idUser.email': this.listQuery['idUser.email'] ? {
          $regex: this.listQuery['idUser.email']
        } : undefined,
        'idUser.name': this.listQuery['idUser.name'] ? {
          $regex: this.listQuery['idUser.name']
        } : undefined,
        '$skip': (this.page - 1) * this.limit,
        '$limit': this.limit
      });
      delete params.createdAtDate;
      delete params.updatedAtDate;
      this.listLoading = true;

      _api.default.getBrandTransactions(params).then(function (res) {
        _this.list = res.data;
        _this.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    resetForm: function resetForm() {
      this.listQuery = this.$_.clone(listQuery);
      this.createdAtDate = undefined;
      this.updatedAtDate = undefined;
      this.getList();
    }
  }
};
exports.default = _default;