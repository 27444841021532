"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vueCountTo = _interopRequireDefault(require("vue-count-to"));

//
//
//
//
//
//
//
var _default = {
  components: {
    countTo: _vueCountTo.default
  }
};
exports.default = _default;