"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFieldsManager = getFieldsManager;
exports.getReferrers = getReferrers;
exports.getWithdrawals = getWithdrawals;
exports.updateWithdrawal = updateWithdrawal;

var _request = _interopRequireDefault(require("@/utils/request"));

var _qs = _interopRequireDefault(require("qs"));

// 获取筛选项数据
function getFieldsManager(params) {
  return (0, _request.default)({
    baseURL: process.env.VUE_APP_WEB_API,
    url: '/field-manager',
    method: 'get',
    params: params
  });
} // 获取推荐人列表


function getReferrers() {
  return (0, _request.default)({
    url: '/referrer',
    method: 'get'
  });
} // 获取推荐人列表


function getWithdrawals(params) {
  return (0, _request.default)({
    url: '/withdrawals',
    method: 'get',
    params: params,
    paramsSerializer: function paramsSerializer(params) {
      return _qs.default.stringify(params);
    }
  });
} // 更细提现账号


function updateWithdrawal(data) {
  return (0, _request.default)({
    url: "/withdrawals/".concat(data.idWithdrawal),
    method: 'patch',
    data: data
  });
}