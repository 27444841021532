"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _api = _interopRequireDefault(require("@/api"));

var _vuex = require("vuex");

var _SubTitle = _interopRequireDefault(require("./components/SubTitle"));

var _PostListAll = _interopRequireDefault(require("./components/PostListAll"));

var _TimeBar = _interopRequireDefault(require("./components/TimeBar"));

var _PostCard = _interopRequireDefault(require("./components/PostCard"));

var _config = _interopRequireDefault(require("@/config/config"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var lineStyleColors = ['#ED9C97', '#F3DF86', '#84BEFF'];
var _default = {
  name: 'PlatFormInfo',
  components: {
    subTitle: _SubTitle.default,
    PostCard: _PostCard.default,
    PostListAll: _PostListAll.default,
    TimeBar: _TimeBar.default
  },
  data: function data() {
    return {
      worldCloubeData: null,
      historicalStatistics: null,
      influencerInfo: null,
      fans: '',
      platformBasePrice: '',
      times: _config.default.timeOptions,
      lineStyleColors: lineStyleColors,
      histtoreTime: 3,
      operateTime: 3,
      worldTime: 3,
      operationalHeat: null,
      postDialog: false,
      postLists: null,
      postHotList: null,
      checkedType: 'weibo',
      currPlatform: '',
      pageSize: 12,
      pageNumber: 1,
      total: 0,
      kolID: null,
      defaultImg: require('@/assets/images/common/default-img.png'),
      allPost: false,
      platforms: [],
      postLoading: false,
      loading: false,
      weekAvgPostnum: 0
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['uid'])), {}, {
    showInfluencerInfo: function showInfluencerInfo() {
      return this.influencerInfo;
    },
    showMore: function showMore() {
      return this.total > 3;
    },
    showPostHotList: function showPostHotList() {
      return this.postHotList && this.postHotList.length > 0;
    },
    showHistoricalStatistics: function showHistoricalStatistics() {
      return this.historicalStatisticsData.data && this.historicalStatisticsData.data.length > 0;
    },
    showOperationalHeatData: function showOperationalHeatData() {
      return this.operationalHeatData.data && this.operationalHeatData.data.length > 0;
    },
    showWorldTimeCloube: function showWorldTimeCloube() {
      return this.worldCloubeData && this.worldCloubeData.title;
    },
    historicalStatisticsData: function historicalStatisticsData() {
      var tplData = this.historicalStatistics;
      var filterData = {};
      var legend = [];
      var calcMaxArr = [];

      for (var key in tplData) {
        if (tplData.hasOwnProperty(key)) {
          if (key === 'times') {
            filterData[key] = tplData[key].slice(0, this.histtoreTime * 4);
          } else if (key === 'data') {
            filterData[key] = tplData[key];

            for (var i = 0, l = tplData[key].length; i < l; i++) {
              var data = tplData[key][i].data.slice(0, this.histtoreTime * 4);
              calcMaxArr.push(data);
              var value = Math.floor(data.reduce(function (prev, curr) {
                return prev + curr;
              }) / data.length);
              legend.push({
                name: tplData[key][i].name,
                value: value
              });
            }
          }
        }
      }

      var calcConcatArr = [];

      for (var _i = 0, _l = calcMaxArr.length; _i < _l; _i++) {
        calcConcatArr.push.apply(calcConcatArr, (0, _toConsumableArray2.default)(calcMaxArr[_i]));
      }

      filterData.max = parseInt(Math.max.apply(null, calcConcatArr));
      filterData.legend = legend;
      return filterData;
    },
    operationalHeatData: function operationalHeatData() {
      var tplData = this.operationalHeat;
      var filterData = {};
      var calcMaxArr = [];

      for (var key in tplData) {
        if (tplData.hasOwnProperty(key)) {
          if (key === 'data') {
            filterData[key] = tplData[key].slice(0, this.operateTime * 5);
            filterData[key].forEach(function (item, index) {
              calcMaxArr[index] = item[1];
            });
          }
        }
      }

      filterData.max = parseInt(Math.max.apply(null, calcMaxArr));
      return filterData;
    },
    worldTimeCloubeData: function worldTimeCloubeData() {
      var tplData = {};

      if (this.showWorldTimeCloube) {
        var list = this.worldCloubeData.list.slice(0, this.worldTime * 4);
        var listArr = [];

        for (var i = 0, l = list.length; i < l; i++) {
          for (var j = 0, len = list[i].length; j < len; j++) {
            listArr.push({
              name: list[i][j][0],
              value: list[i][j][1]
            });
          }
        }

        tplData.data = listArr;
      }

      return tplData;
    }
  }),
  mounted: function mounted() {
    this.platforms = [{
      kolID: '00123b08-ea8c-3072-87d1-88311050c720',
      platform: 'weibo'
    }, {
      kolID: '00123cdc-ed26-313c-8681-45c5a2a9828c',
      platform: 'youtube'
    }];
    this.platforms = this.$storage.session.get('profile').platformKol;
    this.kolID = this.platforms[0].kolID;
    this.getPlatFormInfo();
  },
  methods: {
    // 按需加载后端数据[tab]
    loadTab: function loadTab(tab, event) {
      if (this.load[tab.name] === false) {
        this.load[tab.name] = true;
      }
    },
    getPlatFormInfo: function getPlatFormInfo() {
      this.getInfluencerPost();
      this.getInfluencerDetail();
    },
    getInfluencerPost: function getInfluencerPost() {
      var _this = this;

      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'hot';
      var params = {
        kolID: this.kolID,
        '$skip': (this.pageNumber - 1) * this.pageSize,
        '$limit': type === 'hot' ? 3 : this.pageSize,
        type: this.allPost ? 'all' : 'hot'
      };
      console.log('this.kolID :', this.kolID);
      this.postLoading = true;

      _api.default.getInfluencerPost(params).then(function (res) {
        if (type === 'hot') {
          _this.postHotList = res.data;
        } else {
          _this.postDialog = true;
          _this.total = res.total;
          _this.postLists = res.data;
        }

        _this.postLoading = false;
      }).catch(function () {
        _this.postLoading = false;
      });
    },
    handleMorePosts: function handleMorePosts() {
      this.allPost = true;
      this.postDialog = true;
      this.getInfluencerPost('all');
    },
    changePage: function changePage(obj) {
      this.pageNumber = obj.page;
      this.getInfluencerPost('all');
    },
    updateLimit: function updateLimit(val) {
      this.pageSize = val;
      this.getInfluencerPost('all');
    },
    getInfluencerDetail: function getInfluencerDetail() {
      var _this2 = this;

      this.loading = true;

      _api.default.getInfluencersDetail({
        kolID: this.kolID
      }).then(function (res) {
        console.log('res-getInfluencersDetail :', res);
        _this2.loading = false;
        _this2.fans = res.fans || 0;
        _this2.platformBasePrice = res.platformBasePrice;
        _this2.weekAvgPostnum = res.weekAvgPostNumNew || 0;
        _this2.historicalStatistics = res.statistics.historicalStatistics;
        _this2.operationalHeat = res.statistics.operationalHeat;
        _this2.worldCloubeData = res.statistics.worldCloube;
        console.log('this.weekAvgPostnum1 :', _this2.weekAvgPostnum);
      }).catch(function () {
        _this2.loading = false;
      });
    },
    handlePlatform: function handlePlatform(tab, event) {
      var _this3 = this;

      this.checkedType = tab.name;
      this.allPost = false;
      this.postDialog = false;
      this.platforms.forEach(function (ele) {
        if (ele.platform === tab.name) {
          _this3.kolID = ele.kolID;
        }
      });
      this.$forceUpdate();
      this.getPlatFormInfo();
      this.getInfluencerDetail();
    },
    handleViewPostDetail: function handleViewPostDetail(link) {
      window.open(link);
    },
    handleOperationTime: function handleOperationTime(val) {
      this.operateTime = val.value;
    },
    handleHistoryTime: function handleHistoryTime(val) {
      this.histtoreTime = val.value;
    },
    handleClodTime: function handleClodTime(val) {
      this.worldTime = val.value;
    }
  }
};
exports.default = _default;