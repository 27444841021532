"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var PASSWORD_DEFAULT_VALUE = '------';
var _default = {
  name: 'EditUserDialog',
  props: {
    user: {
      type: Object,
      default: undefined
    },
    roleOptions: {
      type: Array,
      default: undefined
    }
  },
  data: function data() {
    return {
      capsTooltip: false,
      visible: true,
      dirty: false,
      loading: false,
      editUserForm: {
        email: this.user ? this.user.email : '',
        password: PASSWORD_DEFAULT_VALUE,
        name: this.user ? this.user.name : '',
        roles: this.user ? this.user.roles : [],
        active: this.user ? this.user.active : true
      },
      editUserRules: {
        password: [{
          required: false,
          trigger: ['change', 'blur'],
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$/,
          message: '密码为8-16位数字加字母大小写的组合',
          transform: function transform(value) {
            return value.trim();
          },
          validator: function validator(rule, value, callback) {
            if (value === PASSWORD_DEFAULT_VALUE) {
              callback();
            } else if (!rule.pattern.test(value)) {
              callback(new Error(rule.message));
            } else {
              callback();
            }
          }
        }],
        name: [{
          required: true,
          trigger: ['change', 'blur'],
          message: '请输入姓名',
          transform: function transform(value) {
            return value.trim();
          }
        }],
        roles: [{
          required: true,
          trigger: 'change',
          message: '请至少选择一个角色'
        }]
      }
    };
  },
  methods: {
    handleClose: function handleClose(done) {
      var _this = this;

      if (this.dirty) {
        this.$confirm('确定取消所做的更改么？', {
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        }).then(function () {
          _this.resetForm();

          _this.$emit('closeDialog');

          if (done) done();
        }).catch(function () {});
      } else {
        this.resetForm();
        this.$emit('closeDialog');
        if (done) done();
      }
    },
    handleInputChange: function handleInputChange() {
      this.dirty = true;
    },
    resetForm: function resetForm() {
      this.$refs.editUserForm.resetFields();
      this.dirty = false;
    },
    handleUpdateUser: function handleUpdateUser() {
      var _this2 = this;

      if (!this.dirty) {
        this.resetForm();
        this.$emit('closeDialog');
        return true;
      }

      this.$refs.editUserForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;

          _api.default.updateAdminUser(_this2.user._id, {
            password: _this2.editUserForm.password && _this2.editUserForm.password !== PASSWORD_DEFAULT_VALUE ? _this2.editUserForm.password : undefined,
            name: _this2.editUserForm.name || undefined,
            roles: _this2.editUserForm.roles,
            active: _this2.editUserForm.active
          }).then(function () {
            _this2.loading = false;

            _this2.resetForm();

            _this2.$emit('userUpdated');

            _this2.$emit('closeDialog');
          }).catch(function () {
            _this2.loading = false;
          });
        }
      });
    },
    checkCapslock: function checkCapslock() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          shiftKey = _ref.shiftKey,
          key = _ref.key;

      if (key && key.length === 1) {
        if (shiftKey && key >= 'a' && key <= 'z' || !shiftKey && key >= 'A' && key <= 'Z') {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }

      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    handlePasswordFieldFocus: function handlePasswordFieldFocus() {
      if (this.editUserForm.password === PASSWORD_DEFAULT_VALUE) {
        this.editUserForm.password = '';
      }
    },
    handlePasswordFieldBlur: function handlePasswordFieldBlur() {
      this.capsTooltip = false;

      if (this.editUserForm.password === '') {
        this.editUserForm.password = PASSWORD_DEFAULT_VALUE;
      }
    }
  }
};
exports.default = _default;