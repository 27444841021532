"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _table = _interopRequireDefault(require("@/mixin/table"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_table.default],
  data: function data() {
    return {
      baseData: [{}],
      companyData: [{}],
      subscribeData: [{}]
    };
  },
  created: function created() {
    this.mapData();
  },
  methods: {
    mapData: function mapData() {
      var baseInfo = this.$storage.session.get('brandBaseInfo');
      var options = this.$storage.get('options');
      this.referrerOptions = this.$storage.get('referrerOptions');
      this.industriesOptions = options.industry.data;
      this.areaOptions = options.area.data;
      this.baseData[0].name = baseInfo.name;
      this.baseData[0].email = baseInfo.email;
      this.baseData[0].mobile = baseInfo.mobile;
      this.companyData[0].brandName = baseInfo.brandName;
      this.companyData[0].type = baseInfo.type;
      this.companyData[0].area = baseInfo.area;
      this.companyData[0].website = baseInfo.website;
      this.companyData[0].industries = baseInfo.industries;
      this.subscribeData[0].referrer = baseInfo.referrer;
      this.subscribeData[0].createdAt = baseInfo.createdAt;
    }
  }
};
exports.default = _default;