"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Cooperation',
  data: function data() {
    return {
      options: [{
        value: 'all',
        label: '全部'
      }, {
        value: 'pending',
        label: '合作确认'
      }, {
        value: 'inProgressFalse',
        label: '样品发放'
      }, {
        value: 'inProgressTrue',
        label: '推文发布'
      }, {
        value: 'publishedFalse',
        label: '品牌验收'
      }, {
        value: 'publishedTrue',
        label: '合作完成'
      }, {
        value: 'declined',
        label: '合作失效'
      }],
      value: 'all',
      page: 1,
      limit: 10,
      total: 0,
      loading: false,
      list: [],
      getTitle: [],
      getCooperationList: []
    };
  },
  created: function created() {
    var params = {
      $skip: (this.page - 1) * this.limit || 0,
      $limit: this.limit
    };
    this.getCooperation();
    this.getJobs(params);
  },
  methods: {
    comCampaignsStatus: function comCampaignsStatus(status) {
      var statusMap = {
        pending: '',
        inProgress: 'success',
        finished: 'info',
        cancelled: 'danger',
        inApproval: 'warning'
      };
      return statusMap[status];
    },
    handleFilter: function handleFilter(val) {
      console.log(val);
      var params = {
        $skip: (this.page - 1) * this.limit || 0,
        $limit: this.limit,
        status: val
      };

      switch (val) {
        case 'inProgressFalse':
          params = {
            status: 'inProgress',
            packageSent: false,
            $skip: (this.page - 1) * this.limit || 0,
            $limit: this.limit
          };
          break;

        case 'inProgressTrue':
          params = {
            status: 'inProgress',
            packageSent: true,
            $skip: (this.page - 1) * this.limit || 0,
            $limit: this.limit
          };
          break;

        case 'publishedFalse':
          params = {
            status: 'published',
            packageSent: false,
            $skip: (this.page - 1) * this.limit || 0,
            $limit: this.limit
          };
          break;

        case 'publishedTrue':
          params = {
            status: 'published',
            packageSent: true,
            $skip: (this.page - 1) * this.limit || 0,
            $limit: this.limit
          };
          break;

        case 'all':
          params = {
            $skip: (this.page - 1) * this.limit || 0,
            $limit: this.limit
          };
          break;

        default:
          params;
          break;
      }

      this.getJobs(params);
    },
    renderTipsHeader: function renderTipsHeader(h, _ref) {
      var column = _ref.column;
      return h('div', [h('span', column.label), h('el-tooltip', {
        props: {
          effect: 'dark',
          content: '指从样品发放后到推文发布的时长',
          placement: 'top'
        }
      }, [h('i', {
        class: 'el-icon-warning-outline',
        style: 'margin-left:5px;'
      })])]);
    },
    renderROIHeader: function renderROIHeader(h, _ref2) {
      var column = _ref2.column;
      return h('div', [h('span', column.label), h('el-tooltip', {
        props: {
          effect: 'dark',
          content: '投资转化率，ROI = ( 1 - ( 活动费用 / 媒体价值 ) ) * 100%',
          placement: 'top'
        }
      }, [h('i', {
        class: 'el-icon-warning-outline',
        style: 'margin-left:5px;'
      })])]);
    },
    renderRateHeader: function renderRateHeader(h, _ref3) {
      var column = _ref3.column;
      return h('div', [h('span', column.label), h('el-tooltip', {
        props: {
          effect: 'dark',
          content: '发帖速度/帖子内容/整体满意度，满分：5',
          placement: 'top'
        }
      }, [h('i', {
        class: 'el-icon-warning-outline',
        style: 'margin-left:5px;'
      })])]);
    },
    getCooperation: function getCooperation() {
      var _this = this;

      _api.default.getCooperation(this.$route.query.id).then(function (res) {
        _this.getCooperationList = res.data[0].jobSum;
        _this.getTitle = res.data[0];
        if (_this.getTitle.isPrivate) _this.getTitle.isPrivate = '私密';else _this.getTitle.isPrivate = '公开';
        _this.status = _this.getTitle.status;
      });
    },
    getJobs: function getJobs(params) {
      var _this2 = this;

      this.loading = true;

      _api.default.getJobs(this.$route.query.id, params).then(function (res) {
        _this2.loading = false;
        _this2.list = res.data;

        _this2.list.forEach(function (e) {
          if (typeof (e.quote / e.postMediaValue) === 'number') {
            e.ROI = (1 - 0) * 100 + '%';
          } else e.ROI = (1 - e.quote / e.postMediaValue) * 100 + '%';

          if (e.status !== 'published') {
            e.duration = '--';
            e.exposure = '--';
            e.postMediaValue = '--';
            e.ROI = '--';
          }

          if (e.status === 'pending') e.status = '合作确认';

          if (e.status === 'inProgress' && e.packageSent === false) {
            e.status = '样品发放';
          }

          if (e.status === 'inProgress' && e.packageSent === true) {
            e.status = '推文发布';
          }

          if (e.status === 'published' && e.isPaid === false) {
            e.status = '品牌验收';
          }

          if (e.status === 'published' && e.isPaid === true) {
            e.status = '合作完成';
          }

          if (e.status === 'declined') e.status = '合作失效';
        });

        console.log(_this2.list);
        _this2.total = res.total;
      }).catch(function () {
        _this2.loading = false;
      });
    }
  }
};
exports.default = _default;