"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _config = _interopRequireDefault(require("@/config/config"));

var _table = _interopRequireDefault(require("@/mixin/table"));

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'WalletInfo',
  filters: {
    payStatusFilter: function payStatusFilter(val) {
      if (val !== 'pending') {
        return '支付成功';
      }
    }
  },
  mixins: [_table.default],
  data: function data() {
    return {
      id: '',
      dialogVisible: false,
      transactionOptions: _config.default.transactionOptions,
      transaction: {},
      // limit: 20,
      // listLoading: false,
      // page: 1,
      // total: 0,
      available: 0,
      campaigns: 0,
      freeze: 0,
      listQuery: {
        startAt: undefined,
        endAt: undefined,
        date: undefined,
        type: undefined
      } // list: [],
      // pickerOptions: config.pickerOptions

    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    getBrandWallet: function getBrandWallet(id) {
      var _this = this;

      var params = {
        idUser: id
      };

      _api.default.getBrandWallet(params).then(function (res) {
        console.log('getBrandWallet :', res);
        _this.available = res.data[0].available;
        _this.freeze = res.data[0].freeze;
        _this.campaigns = res.data[0].campaigns;
      });
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        idUser: this.id,
        '$skip': (this.page - 1) * this.limit,
        '$limit': this.limit
      });
      delete params.date;

      _api.default.getBrandTransactions(params).then(function (res) {
        console.log('getBrandTransactions :', res);
        _this2.listLoading = false;
        _this2.list = res.data;
        _this2.total = res.total;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    getBrandTransactions: function getBrandTransactions() {
      _api.default.getBrandTransactions().then(function (res) {
        console.log('getBrandTransactions :', res);
      });
    },
    getData: function getData() {
      this.id = this.$route.params.id;
      console.log('this.id :', this.id);
      this.getBrandWallet(this.id);
      this.getList();
    },
    // formatUtcDate(date) {
    //   return this.$moment(date)
    //     .utc()
    //     .format()
    // },
    handleDate: function handleDate(row) {
      console.log('date :', row);
      this.listQuery.startAt = this.formatUtcDate(this.listQuery.date[0]);
      this.listQuery.endAt = this.formatUtcDate(this.listQuery.date[1]);
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    // updateLimit(val) {
    //   this.limit = val
    //   this.handleFilter()
    // },
    handleViewTransaction: function handleViewTransaction(row) {
      console.log('row :', row);
      this.transaction = row;
      this.dialogVisible = true;
    }
  }
};
exports.default = _default;