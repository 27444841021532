"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchList = fetchList;
exports.getDetail = getDetail;
exports.getInviteRequestTicketLists = getInviteRequestTicketLists;
exports.getFollowUpList = getFollowUpList;
exports.postKOLInfo = postKOLInfo;
exports.getKOLInfo = getKOLInfo;
exports.patchKOLInfo = patchKOLInfo;
exports.getInfluencersDetail = getInfluencersDetail;
exports.getInfluencerPost = getInfluencerPost;
exports.getWorkorders = getWorkorders;
exports.updateWorkorders = updateWorkorders;
exports.influencersStatus = influencersStatus;

var _request = _interopRequireDefault(require("@/utils/request"));

var _qs = _interopRequireDefault(require("qs"));

// 获取入驻kol列表
function fetchList(params) {
  return (0, _request.default)({
    url: '/influencers',
    method: 'get',
    params: params,
    paramsSerializer: function paramsSerializer(params) {
      return _qs.default.stringify(params);
    }
  });
} // 获取入驻kol基础信息


function getDetail(id) {
  return (0, _request.default)({
    url: "/influencers/".concat(id),
    method: 'get'
  });
}

function getInviteRequestTicketLists() {
  return (0, _request.default)({
    url: '/notifications',
    method: 'get'
  });
} // 获取待挖掘kol列表


function getFollowUpList(params) {
  return (0, _request.default)({
    url: '/targetuser',
    method: 'get',
    params: params
  });
} // 新增待挖掘kol


function postKOLInfo(data) {
  return (0, _request.default)({
    url: '/targetuser',
    method: 'post',
    data: data
  });
} // 获取待挖掘kol编辑状态信息


function getKOLInfo(id) {
  return (0, _request.default)({
    url: "/targetuser/".concat(id),
    method: 'get'
  });
} // 编辑待挖掘kol信息


function patchKOLInfo(id, data) {
  return (0, _request.default)({
    url: "/targetuser/".concat(id),
    method: 'put',
    data: data
  });
} // 获取用户平台信息


function getInfluencersDetail(params) {
  return (0, _request.default)({
    url: '/platform',
    method: 'get',
    params: params
  });
} // 获取平台帖子


function getInfluencerPost(params) {
  return (0, _request.default)({
    url: '/posts',
    method: 'get',
    params: params
  });
} // 获取申请入驻的KOL


function getWorkorders(params) {
  return (0, _request.default)({
    url: '/workorders',
    method: 'get',
    params: params,
    paramsSerializer: function paramsSerializer(params) {
      return _qs.default.stringify(params);
    }
  });
} // 更新KOL申请KOL


function updateWorkorders(id, data) {
  return (0, _request.default)({
    url: "/workorders/".concat(id),
    method: 'patch',
    data: data
  });
}

function influencersStatus(params) {
  return (0, _request.default)({
    url: '/influencers-status',
    method: 'get',
    params: params
  });
}