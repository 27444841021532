var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("router-view"),
      _vm._v(" "),
      _vm.showPage
        ? _c(
            "main",
            { staticClass: "brand-wrap" },
            [
              _c(
                "el-row",
                { staticClass: "filter-container clearfix" },
                [
                  _c(
                    "el-col",
                    [
                      _c("el-input", {
                        staticClass: "width-250",
                        attrs: {
                          placeholder: "品牌名、手机或邮箱",
                          size: "medium"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleFilter($event)
                          }
                        },
                        model: {
                          value: _vm.listQuery.keyword,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "keyword", $$v)
                          },
                          expression: "listQuery.keyword"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "width-140",
                          attrs: { placeholder: "国家", size: "medium" },
                          on: { change: _vm.handleFilter },
                          model: {
                            value: _vm.listQuery.country,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "country", $$v)
                            },
                            expression: "listQuery.country"
                          }
                        },
                        _vm._l(_vm.areaOptions, function(value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value, value: key }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "width-140",
                          attrs: { placeholder: "行业标签", size: "medium" },
                          on: { change: _vm.handleFilter },
                          model: {
                            value: _vm.listQuery.industries,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "industries", $$v)
                            },
                            expression: "listQuery.industries"
                          }
                        },
                        _vm._l(_vm.industriesOptions, function(value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value, value: key }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "入驻时间起",
                          "end-placeholder": "入驻时间止",
                          "picker-options": _vm.pickerOptions,
                          "default-time": ["00:00:00", "23:59:59"]
                        },
                        on: { change: _vm.handleDate },
                        model: {
                          value: _vm.listQuery.date,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "date", $$v)
                          },
                          expression: "listQuery.date"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.resetForm("listQuery")
                            }
                          }
                        },
                        [_vm._v("重置")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { type: "primary" },
                          on: { click: _vm.handleFilter }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  staticClass: "table-wrap",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "default-sort": { prop: "createdAt", order: "descending" },
                    data: _vm.list,
                    border: "",
                    stripe: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "品牌ID",
                      "min-width": "210",
                      align: "left"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [_c("span", [_vm._v(_vm._s(row._id))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2154421622
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "品牌信息",
                      "min-width": "240",
                      align: "left"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("div", [
                                _c("i", { staticClass: "el-icon-user" }),
                                _vm._v(" "),
                                row.name
                                  ? _c("span", [_vm._v(" " + _vm._s(row.name))])
                                  : _c("span", [_vm._v("--")])
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("i", { staticClass: "el-icon-message" }),
                                _vm._v(" "),
                                row.email
                                  ? _c("span", [_vm._v(_vm._s(row.email))])
                                  : _c("span", [_vm._v("--")])
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("i", { staticClass: "el-icon-phone" }),
                                _vm._v(" "),
                                row.mobile
                                  ? _c("span", [_vm._v(_vm._s(row.mobile))])
                                  : _c("span", [_vm._v("--")])
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1346882382
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "行业标签",
                      "min-width": "220",
                      align: "left",
                      "class-name": "industries"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._l(row.industries, function(industry) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: industry,
                                    staticClass: "mr-8 mb-8",
                                    attrs: { type: "success" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.industriesOptions[industry])
                                    )
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              row.industries.length == 0
                                ? _c("span", [_vm._v("--")])
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1840088986
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "国家", "min-width": "80", align: "left" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.country
                                ? _c("span", [_vm._v(_vm._s(row.country))])
                                : _c("span", [_vm._v("--")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      306249527
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "官网", "min-width": "250", align: "left" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.website
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: row.website,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(_vm._s(row.website))]
                                  )
                                : _c("span", [_vm._v("--")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3159430242
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "入驻时间",
                      prop: "createdAt",
                      sortable: "",
                      "min-width": "110",
                      align: "left"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatUTCDate")(
                                      row.createdAt,
                                      "YYYY-MM-DD"
                                    )
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4119576081
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "企业类型",
                      "min-width": "80",
                      align: "left"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.type
                                ? _c(
                                    "div",
                                    [
                                      row.type === "brand"
                                        ? _c(
                                            "el-tag",
                                            { staticClass: "tag-brand" },
                                            [_vm._v("品牌方")]
                                          )
                                        : _c(
                                            "el-tag",
                                            { attrs: { type: "danger" } },
                                            [_vm._v("代理商")]
                                          )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      903435144
                    )
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0"
                  }
                ],
                attrs: { total: _vm.total, page: _vm.page, limit: _vm.limit },
                on: {
                  "update:page": function($event) {
                    _vm.page = $event
                  },
                  "update:limit": [
                    function($event) {
                      _vm.limit = $event
                    },
                    _vm.updateLimit
                  ],
                  pagination: _vm.getList
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }