exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".role-overview>div+div{margin-top:20px}.filter-container label+.el-input{margin-left:10px}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "210px"
};