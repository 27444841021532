"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      loading: false,
      id: '',
      profile: {},
      baseInfo: {},
      platforms: [],
      areaOptions: [],
      industriesOptions: []
    };
  },
  computed: {
    showPlatform: function showPlatform() {
      return this.platforms && this.platforms.some(function (item) {
        return item.kolID !== null;
      });
    },
    fiterCreatedAt: function fiterCreatedAt() {
      return this.profile.claimedOn && this.$moment.utc().format('YYYY-MM-DD hh:mm:ss');
    }
  },
  created: function created() {
    this.getID();
    this.getFieldsManager();
    this.getBaseInfo();
  },
  methods: {
    getID: function getID() {
      this.id = this.$route.params.id;
      this.profile = this.$storage.session.get('profile');
    },
    getFieldsManager: function getFieldsManager() {
      var options = this.$storage.get('options');
      this.areaOptions = options.area.data;
      this.industriesOptions = options.industry.data;
    },
    getBaseInfo: function getBaseInfo() {
      var _this = this;

      this.loading = true;

      _api.default.getDetail(this.id).then(function (res) {
        console.log('res-getDetail :', res);
        _this.loading = false;
        _this.baseInfo = res;
        console.log('this.baseInfo :', _this.baseInfo);
      }).catch(function () {
        _this.loading = false;
      });
    }
  }
};
exports.default = _default;