var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "platform-wrap"
    },
    [
      _vm.platforms && _vm.platforms.length > 0
        ? _c(
            "el-tabs",
            {
              staticClass: "tab-platform",
              on: { "tab-click": _vm.handlePlatform },
              model: {
                value: _vm.checkedType,
                callback: function($$v) {
                  _vm.checkedType = $$v
                },
                expression: "checkedType"
              }
            },
            _vm._l(_vm.platforms, function(tab) {
              return _c(
                "el-tab-pane",
                { key: tab.platform, attrs: { name: tab.platform } },
                [
                  _c("svg-icon", {
                    attrs: {
                      slot: "label",
                      "icon-class": tab.platform + "-on"
                    },
                    slot: "label"
                  }),
                  _vm._v(" "),
                  _vm.checkedType === tab.platform
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "person-wrap" }, [
                            _c(
                              "div",
                              { staticClass: "numbers text-gray-900" },
                              [
                                _c("span", [
                                  _vm._v(
                                    "粉丝数：：" +
                                      _vm._s(
                                        _vm._f("numberFormatter")(tab.fans)
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "周平均发文数：" +
                                      _vm._s(
                                        _vm._f("numberFormatter")(
                                          _vm.weekAvgPostnum
                                        )
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "score" },
                                  [
                                    _vm._v(
                                      "\n              底薪： " +
                                        _vm._s(Math.round(tab.platformPrice)) +
                                        "\n              "
                                    ),
                                    _c("tip-msg", {
                                      attrs: {
                                        placement: "top-start",
                                        trigger: "hover",
                                        content:
                                          "Navigate平台评估推文产生的影响力指标，根据帖子产生的观看量、点赞量、评论量、转发量等数据计算而来。"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("sub-title", {
                            staticClass: "hot-post",
                            attrs: { title: "热门帖子" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "tip",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "score" },
                                        [
                                          _vm._v(
                                            "\n              媒体价值 " +
                                              _vm._s(
                                                Math.round(tab.platformPrice)
                                              ) +
                                              "\n              "
                                          ),
                                          _c("tip-msg", {
                                            attrs: {
                                              placement: "top-start",
                                              width: "260",
                                              trigger: "hover",
                                              content:
                                                "指和KOL合作投放内容至某一平台的合作底薪，和KOL个人影响力、平台评分（2dmScore）、历史品牌合作表现有关。"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "tool",
                                  fn: function() {
                                    return [
                                      _c("div", { staticClass: "tool hover" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "hover pointer",
                                            on: { click: _vm.handleMorePosts }
                                          },
                                          [
                                            _vm._v(
                                              "\n                更多\n                "
                                            ),
                                            _c("i", {
                                              staticClass: "el-icon-arrow-right"
                                            })
                                          ]
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _vm._v(" "),
                          _vm.showPostHotList
                            ? _c(
                                "div",
                                { staticClass: "post-list" },
                                _vm._l(_vm.postHotList, function(item) {
                                  return _c("post-card", {
                                    key: item.id,
                                    attrs: {
                                      id: item.link,
                                      link: item.link,
                                      image: item.image
                                        ? item.image
                                        : _vm.defaultImg,
                                      "retweet-number": item.retweet,
                                      "comment-number": item.comment,
                                      "like-number": item.like,
                                      "platform-media-vale":
                                        item.platformMediaVale,
                                      desc: item.description
                                    },
                                    on: {
                                      handleViewPostDetail:
                                        _vm.handleViewPostDetail
                                    }
                                  })
                                }),
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showHistoricalStatistics
                            ? _c(
                                "div",
                                { staticClass: "chart-wrap chart-statistics" },
                                [
                                  _c("sub-title", {
                                    attrs: { title: "历史帖子统计" }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "line-main bg-white" },
                                    [
                                      _c("time-bar", {
                                        attrs: {
                                          times: _vm.times,
                                          position: "right"
                                        },
                                        on: {
                                          handleTime: _vm.handleHistoryTime
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "line-chart" },
                                        [
                                          _c("chart-line", {
                                            attrs: {
                                              colors: _vm.lineStyleColors,
                                              "chart-data":
                                                _vm.historicalStatisticsData
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "chart-wrap" },
                            [
                              _c("sub-title", {
                                attrs: { title: "运营热度" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "tip",
                                      fn: function() {
                                        return [
                                          _c("tip-msg", {
                                            attrs: {
                                              placement: "top-start",
                                              width: "260",
                                              trigger: "hover",
                                              content:
                                                "表示KOL在不同时间发布的推文数量及推文产生的影响力，发布推文越多气泡纵坐标越高，推文影响力越大气泡越大。"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _vm._v(" "),
                              _vm.showOperationalHeatData
                                ? _c(
                                    "div",
                                    { staticClass: "scatter-main bg-white" },
                                    [
                                      _c("time-bar", {
                                        attrs: {
                                          times: _vm.times,
                                          position: "right"
                                        },
                                        on: {
                                          handleTime: _vm.handleOperationTime
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "scatter-chart" },
                                        [
                                          _c("chart-scatter", {
                                            attrs: {
                                              time: _vm.operateTime,
                                              "chart-data":
                                                _vm.operationalHeatData
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showWorldTimeCloube
                            ? _c(
                                "div",
                                { staticClass: "chart-wrap" },
                                [
                                  _c("sub-title", {
                                    attrs: { title: "品牌提及词云" }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "chart-main bg-white" },
                                    [
                                      _c("time-bar", {
                                        attrs: {
                                          times: _vm.times,
                                          position: "right"
                                        },
                                        on: { handleTime: _vm.handleClodTime }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "word-cloud",
                                          staticStyle: { height: "422px" }
                                        },
                                        [
                                          _c("word-cloud", {
                                            attrs: {
                                              "chart-data":
                                                _vm.worldTimeCloubeData
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _c("no-data", {
                        staticClass: "bg-white mt-20",
                        attrs: { "min-height": 600, desc: "数据生成中..." }
                      })
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showPostHotList
        ? _c(
            "pop-view",
            {
              attrs: {
                width: "1200px",
                show: _vm.postDialog,
                title: "所有帖子"
              },
              on: {
                "update:show": function($event) {
                  _vm.postDialog = $event
                }
              }
            },
            [
              _c("post-list-all", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.postLoading,
                    expression: "postLoading"
                  }
                ],
                attrs: { column: _vm.postLists },
                on: { handleViewPostDetail: _vm.handleViewPostDetail }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block-center mb-30" },
                [
                  _c("pagination", {
                    attrs: {
                      total: _vm.total,
                      page: _vm.pageNumber,
                      limit: _vm.pageSize,
                      "page-sizes": [12, 16, 20]
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.pageNumber = $event
                      },
                      "update:limit": [
                        function($event) {
                          _vm.pageSize = $event
                        },
                        _vm.updateLimit
                      ],
                      pagination: _vm.changePage
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }