var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("section", { staticClass: "filter-container clearfix" }, [
        _c(
          "form",
          { attrs: { id: "form" } },
          [
            _c("el-input", {
              staticClass: "form-item",
              attrs: { placeholder: "用户ID" },
              model: {
                value: _vm.listQuery.id,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "id", $$v)
                },
                expression: "listQuery.id"
              }
            }),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "form-item",
              attrs: { placeholder: "用户名" },
              model: {
                value: _vm.listQuery.displayName,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "displayName", $$v)
                },
                expression: "listQuery.displayName"
              }
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "form-item",
                attrs: { placeholder: "角色" },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.role,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "role", $$v)
                  },
                  expression: "listQuery.role"
                }
              },
              _vm._l(_vm.roleOptions, function(item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.name, value: item.value }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "form-item",
                attrs: { placeholder: "启用状态" },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.status,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "status", $$v)
                  },
                  expression: "listQuery.status"
                }
              },
              _vm._l(_vm.statusOptions, function(item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.name, value: item.value }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-refresh-left" },
                on: {
                  click: function($event) {
                    return _vm.resetForm("listQuery")
                  }
                }
              },
              [_vm._v("重置")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { marginTop: "20px" },
                attrs: { type: "success", icon: "el-icon-circle-plus-outline" },
                on: { click: _vm.handleAddAdministrator }
              },
              [_vm._v("新增")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                staticStyle: { marginTop: "20px", marginLeft: "10px" },
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.handleFilter }
              },
              [_vm._v("查询")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: { data: _vm.administrators, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.id))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户名", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.displayName))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "名字", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.name))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "角色", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(_vm.comRole(scope.row.role)))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "启用状态", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      on: {
                        change: function($event) {
                          return _vm.handleStatus(scope, scope.row.status)
                        }
                      },
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status "
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: { total: _vm.total, page: _vm.page, limit: _vm.limit },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:limit": [
            function($event) {
              _vm.limit = $event
            },
            _vm.updateLimit
          ],
          pagination: _vm.getAdministrators
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: _vm.dialogType === "edit" ? "编辑" : "新增"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px", "label-position": "left" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "用户名" },
                    model: {
                      value: _vm.administrator.displayName,
                      callback: function($$v) {
                        _vm.$set(_vm.administrator, "displayName", $$v)
                      },
                      expression: "administrator.displayName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名字" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "名字" },
                    model: {
                      value: _vm.administrator.name,
                      callback: function($$v) {
                        _vm.$set(_vm.administrator, "name", $$v)
                      },
                      expression: "administrator.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "角色" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-full",
                      attrs: { placeholder: "角色" },
                      model: {
                        value: _vm.administrator.role,
                        callback: function($$v) {
                          _vm.$set(_vm.administrator, "role", $$v)
                        },
                        expression: "administrator.role"
                      }
                    },
                    _vm._l(_vm.roleOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "密码" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "密码", type: "password" },
                    model: {
                      value: _vm.administrator.password,
                      callback: function($$v) {
                        _vm.$set(_vm.administrator, "password", $$v)
                      },
                      expression: "administrator.password"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "确认密码", type: "password" },
                    model: {
                      value: _vm.password1,
                      callback: function($$v) {
                        _vm.password1 = $$v
                      },
                      expression: "password1"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmRole } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }