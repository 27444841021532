"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toThousandFilter = toThousandFilter;
exports.toFixed2 = toFixed2;
exports.formatUTCLocalDate = formatUTCLocalDate;
exports.uppercaseFirst = uppercaseFirst;
exports.percentageFormatter = percentageFormatter;
exports.toDecimal2 = toDecimal2;
exports.timeAgo = timeAgo;
exports.numberFormatter = numberFormatter;
exports.statusSex = statusSex;
exports.activeState = activeState;
exports.formatUTCDate = formatUTCDate;
exports.filterInfluence = filterInfluence;
Object.defineProperty(exports, "parseTime", {
  enumerable: true,
  get: function get() {
    return _utils.parseTime;
  }
});
Object.defineProperty(exports, "formatTime", {
  enumerable: true,
  get: function get() {
    return _utils.formatTime;
  }
});

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.replace");

var _moment = _interopRequireDefault(require("moment"));

var _utils = require("@/utils");

/**
 * 10000 => "10,000"
 * @param {number} num
 */
function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
  });
}
/**
 * 保留N位小数
 * @param {*} number
 * @param {*} suffix
 */


function toFixed2(number) {
  var suffix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var str = 0;

  switch (suffix) {
    case 0:
      str = Math.round(number);
      break;

    case 1:
      str = (Math.round(number * 10) / 10).toFixed(suffix);
      break;

    case 2:
      str = (Math.round(number * 100) / 100).toFixed(suffix);
      break;

    case 3:
      str = (Math.round(number * 1000) / 1000).toFixed(suffix);
      break;

    case 4:
      str = (Math.round(number * 1000) / 1000).toFixed(suffix);
      break;

    default:
      str = (Math.round(number * 100) / 100).toFixed(2);
      break;
  }

  return str;
}

function formatUTCLocalDate(utc_datetime) {
  var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'LL';

  if (fmt === 'LL') {
    return (0, _moment.default)(utc_datetime).format('YYYY-MM-DD');
  } else {
    return (0, _moment.default)(utc_datetime).format('YYYY-MM-DD hh:mm:ss');
  }
}
/**
 * Upper case first char
 * @param {String} string
 */


function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function percentageFormatter(number, digits) {
  var result;

  if (digits === null || digits === undefined) {
    digits = 2;
  }

  digits = parseInt(digits);

  if (number === null || number === '' || isNaN(number)) {
    result = '-';
  } else {
    result = Math.round(number * Math.pow(10, digits) * 100) / Math.pow(10, digits) + '%';
  }

  return result;
}

function toDecimal2(val) {
  val = val.toString().replace(/\$|\,/g, '');

  if (isNaN(val)) {
    val = '0';
  }

  var sign = val === (val = Math.abs(val));
  val = Math.floor(val * 100 + 0.50000000001);
  var cents = val % 100;
  val = Math.floor(val / 100).toString();

  if (cents < 10) {
    cents = '0' + cents;
  }

  for (var i = 0; i < Math.floor((val.length - (1 + i)) / 3); i++) {
    val = val.substring(0, val.length - (4 * i + 3)) + val.substring(val.length - (4 * i + 3));
  }

  return (sign ? '' : '-') + val + '.' + cents;
} // import parseTime, formatTime and set to filter


/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }

  return time + label + 's';
}
/**
 * @param {number} time
 */


function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);

  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}
/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */


function numberFormatter(n) {
  var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

  // Alter numbers larger than 1k
  if (n >= 1e3) {
    var units = ['K', 'M', 'B', 'T']; // Divide to get SI Unit engineering style numbers (1e3,1e6,1e9, etc)

    var unit = Math.floor((n.toFixed(0).length - 1) / 3) * 3; // Calculate the remainder

    var num = (n / ('1e' + unit)).toFixed(digits);
    var unitname = units[Math.floor(unit / 3) - 1]; // output number remainder + unitname

    return num + unitname;
  } // return formatted original number


  return n;
}
/**
 * @param {number} sex
 */


function statusSex(sex) {
  var statusMap = ['男', '女', '泛性别'];
  return statusMap[sex - 1];
}
/**
 * @param {string} status
 */


function activeState(status) {
  var statusMap = {
    inApproval: '审核中',
    rejected: '审核驳回',
    inProgress: '招募中',
    finished: '已结束',
    cancelled: '招募暂停'
  };
  return statusMap[status];
}
/**
 * 日期UTC时间过滤器
 * @param { Date } Date
 * @param { String } type 'YYYY-MM-DD hh:mm:ss'
 */


function formatUTCDate(utc_datetime) {
  var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD HH:mm:ss';
  return (0, _moment.default)(utc_datetime).format(fmt);
}

function filterInfluence(influence) {
  switch (influence) {
    case '60':
      return 'D';

    case '70':
      return 'C';

    case '80':
      return 'B';

    case '90':
      return 'A';

    case '99':
      return 'S';
  }
}