"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _api = _interopRequireDefault(require("@/api"));

var _config = _interopRequireDefault(require("@/config/config"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var listQuery = {
  createdAtStart: null,
  createdAtEnd: null,
  status: null,
  platform: null
};
var _default = {
  filters: {
    statusFilter: function statusFilter(val) {
      var mapKey = {
        pending: '已申请',
        inProgress: '处理中',
        approved: '已通过',
        reject: '已驳回'
      };
      return mapKey[val];
    }
  },
  data: function data() {
    return {
      listQuery: this.$_.clone(listQuery),
      page: 1,
      limit: 10,
      total: 0,
      list: [],
      listLoading: false,
      pickerOptions: _config.default.pickerOptions,
      date: null,
      ids: [],
      message: 'navigate.com',
      platformOptions: [{
        name: '小红书',
        value: 'xiaohongshu'
      }, {
        name: 'Tiktok',
        value: 'tiktok'
      }, {
        name: 'Instagram',
        value: 'instagram'
      }, {
        name: 'Youtube',
        value: 'youtube'
      }, {
        name: '微博',
        value: 'weibo'
      }, {
        name: '抖音',
        value: 'douyin'
      }],
      statusOptions: [{
        name: '已申请',
        value: 'pending'
      }, {
        name: '处理中',
        value: 'inProgress'
      }, {
        name: '已通过',
        value: 'approved'
      }, {
        name: '已驳回',
        value: 'reject'
      }]
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['uid'])),
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    onCopy: function onCopy(e) {
      this.$message({
        message: '你已经复制：' + e.text,
        type: 'success'
      });
    },
    onError: function onError(e) {
      this.$message({
        message: '复制失败',
        type: 'error'
      });
    },
    formatUTCDate: function formatUTCDate(utc_datetime) {
      var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD';
      return this.$moment(utc_datetime).format(fmt);
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    handleDate: function handleDate(row) {
      if (row) {
        this.listQuery.createdAtStart = this.formatUTCDate(this.date[0]);
        this.listQuery.createdAtEnd = this.formatUTCDate(this.date[1]);
      } else {
        this.listQuery.createdAtStart = null;
        this.listQuery.createdAtEnd = null;
      }
    },
    getList: function getList() {
      var _this = this;

      var params = {
        'createdAt[$gte]': this.date ? this.date[0] : null,
        'createdAt[$lte]': this.date ? this.date[1] : null,
        status: this.listQuery.status ? this.listQuery.status : null,
        'platformInfo.platform': this.listQuery.platform ? this.listQuery.platform : null,
        '$skip': (this.page - 1) * this.limit,
        '$limit': this.limit,
        'type': 'invite'
      };
      this.listLoading = true;
      console.log(params);

      _api.default.getWorkorders(params).then(function (res) {
        _this.list = res.data;
        _this.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.ids = val.map(function (item) {
        return item._id;
      });
      console.log(this.ids);
    },
    handleDispensing: function handleDispensing(row) {
      var _this2 = this;

      var params = {
        'status': 'inProgress',
        'idWorkorder': row._id
      };
      this.$confirm('系统即将出款，此操作不可撤销, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.handleChangeWithwral(row._id, params);
      });
    },
    handleRefund: function handleRefund(row) {
      var _this3 = this;

      this.$prompt('请输入驳回理由', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (_ref) {
        var value = _ref.value;
        var params = {
          'status': 'reject',
          'reason': value,
          'idWorkorder': row._id
        };

        _this3.handleChangeWithwral(row._id, params);
      });
    },
    handleChangeWithwral: function handleChangeWithwral(id, params) {
      var _this4 = this;

      _api.default.updateWorkorders(id, params).then(function (res) {
        _this4.getList();
      });
    },
    resetForm: function resetForm() {
      this.listQuery = this.$_.clone(listQuery);
      this.date = null;
      this.handleFilter();
    }
  }
};
exports.default = _default;