var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑用户",
        width: "400px",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "editUserForm",
          attrs: {
            model: _vm.editUserForm,
            rules: _vm.editUserRules,
            autocomplete: "off",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "email", label: "邮箱", required: "" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: "",
                  placeholder: "请输入邮箱",
                  size: "medium"
                },
                model: {
                  value: _vm.editUserForm.email,
                  callback: function($$v) {
                    _vm.$set(_vm.editUserForm, "email", $$v)
                  },
                  expression: "editUserForm.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "大写锁定",
                placement: "right-end",
                manual: "",
                tabindex: -1
              },
              model: {
                value: _vm.capsTooltip,
                callback: function($$v) {
                  _vm.capsTooltip = $$v
                },
                expression: "capsTooltip"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "password", label: "密码", required: "" } },
                [
                  _c("el-input", {
                    ref: "password",
                    attrs: {
                      autocomplete: "new-password",
                      type: "password",
                      placeholder: "密码",
                      name: "password"
                    },
                    on: {
                      focus: _vm.handlePasswordFieldFocus,
                      blur: _vm.handlePasswordFieldBlur,
                      change: _vm.handleInputChange
                    },
                    nativeOn: {
                      keyup: function($event) {
                        return _vm.checkCapslock($event)
                      }
                    },
                    model: {
                      value: _vm.editUserForm.password,
                      callback: function($$v) {
                        _vm.$set(_vm.editUserForm, "password", $$v)
                      },
                      expression: "editUserForm.password"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "name", label: "姓名", required: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入姓名", size: "medium" },
                on: { change: _vm.handleInputChange },
                model: {
                  value: _vm.editUserForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.editUserForm, "name", $$v)
                  },
                  expression: "editUserForm.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "roles", label: "角色", required: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", placeholder: "请选择一个角色" },
                  on: { change: _vm.handleInputChange },
                  model: {
                    value: _vm.editUserForm.roles,
                    callback: function($$v) {
                      _vm.$set(_vm.editUserForm, "roles", $$v)
                    },
                    expression: "editUserForm.roles"
                  }
                },
                _vm._l(_vm.roleOptions, function(role) {
                  return _c("el-option", {
                    key: role._id,
                    attrs: { label: role.label, value: role._id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-switch", {
                attrs: { id: "editUserStatus" },
                on: { change: _vm.handleInputChange },
                model: {
                  value: _vm.editUserForm.active,
                  callback: function($$v) {
                    _vm.$set(_vm.editUserForm, "active", $$v)
                  },
                  expression: "editUserForm.active"
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "editUserStatus" } }, [
                _vm._v("启用")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleUpdateUser }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }