"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _KolInfo = _interopRequireDefault(require("../components/KolInfo"));

//
//
//
//
//
var _default = {
  components: {
    AddKol: _KolInfo.default
  }
};
exports.default = _default;