"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var global = {
  methods: {
    fetchReferrers: function fetchReferrers() {
      var _this = this;

      return new Promise(function (resolve) {
        _api.default.getReferrers().then(function (res) {
          if (!res.data) {
            return;
          }

          var referrerOptions = res.data;
          console.log('获取跟进人列表成功');

          _this.$storage.session.set('referrerOptions', referrerOptions);

          resolve();
        });
      });
    },
    fetchOptions: function fetchOptions() {
      var _this2 = this;

      return new Promise(function (resolve) {
        var params = {
          platform: 1,
          area: 1,
          sourceOptions: 1,
          followStatusOptions: 1,
          influence: 1,
          priorityOptions: 1,
          industry: 1
        };

        _api.default.getFieldsManager(params).then(function (res) {
          console.log('获取选项列表成功');

          _this2.$storage.set('areaOptions', res.areaAll.data);

          _this2.$storage.set('industriesOptions', res.industryAll.data);

          _this2.$storage.set('platformOptions', res.platformAll.data);

          _this2.$storage.set('influenceOptions', res.influenceAll.data);

          _this2.$storage.set('fansOptions', res.fans.data);

          resolve();
        });
      });
    }
  }
};
var _default = global;
exports.default = _default;