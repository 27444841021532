"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _goodStorage = _interopRequireDefault(require("good-storage"));

var _default = {
  install: function install(Vue) {
    var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '$storage';
    Object.defineProperty(Vue.prototype, name, {
      value: _goodStorage.default
    });
  }
};
exports.default = _default;