var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("router-view"),
      _vm._v(" "),
      _vm.showPage
        ? _c(
            "main",
            { staticClass: "intake-wrap" },
            [
              _c(
                "el-row",
                { staticClass: "filter-container clearfix" },
                [
                  _c(
                    "el-col",
                    [
                      _c("el-input", {
                        staticClass: "width-140",
                        attrs: { placeholder: "用户昵称" },
                        model: {
                          value: _vm.listQuery.displayName,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "displayName", $$v)
                          },
                          expression: "listQuery.displayName"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-cascader", {
                        staticClass: "width-140",
                        attrs: {
                          "change-on-select": "true",
                          placeholder: "请选择区域",
                          options: _vm.areaOptions
                        },
                        on: { change: _vm.handleFilter },
                        model: {
                          value: _vm.listQuery.area,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "area", $$v)
                          },
                          expression: "listQuery.area"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "width-140",
                          attrs: { placeholder: "社交媒体平台" },
                          on: { change: _vm.handleFilter },
                          model: {
                            value: _vm.listQuery.platforms,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "platforms", $$v)
                            },
                            expression: "listQuery.platforms"
                          }
                        },
                        _vm._l(_vm.platformOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "width-140",
                          attrs: { placeholder: "行业类型" },
                          on: { change: _vm.handleFilter },
                          model: {
                            value: _vm.listQuery.industries,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "industries", $$v)
                            },
                            expression: "listQuery.industries"
                          }
                        },
                        _vm._l(_vm.industriesOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "width-140",
                          attrs: { placeholder: "来源渠道" },
                          on: { change: _vm.handleFilter },
                          model: {
                            value: _vm.listQuery.source,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "source", $$v)
                            },
                            expression: "listQuery.source"
                          }
                        },
                        _vm._l(_vm.sourceOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticClass: "mt-20",
                        attrs: {
                          type: "daterange",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "创建开始时间",
                          "end-placeholder": "创建结束时间",
                          "picker-options": _vm.pickerOptions
                        },
                        on: { change: _vm.handleDate },
                        model: {
                          value: _vm.listQuery.date,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "date", $$v)
                          },
                          expression: "listQuery.date"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "mb-18" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width-140",
                          attrs: { placeholder: "优先级" },
                          on: { change: _vm.handleFilter },
                          model: {
                            value: _vm.listQuery.priority,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "priority", $$v)
                            },
                            expression: "listQuery.priority"
                          }
                        },
                        _vm._l(_vm.priorityOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "width-140",
                          attrs: { placeholder: "跟进状态" },
                          on: { change: _vm.handleFilter },
                          model: {
                            value: _vm.listQuery.status,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "status", $$v)
                            },
                            expression: "listQuery.status"
                          }
                        },
                        _vm._l(_vm.followStatusOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "width-140",
                          attrs: { placeholder: "跟进人" },
                          on: { change: _vm.handleFilter },
                          model: {
                            value: _vm.listQuery.referral,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "referral", $$v)
                            },
                            expression: "listQuery.referral"
                          }
                        },
                        _vm._l(_vm.referrerOptions, function(item) {
                          return _c("el-option", {
                            key: item._id,
                            attrs: { label: item.displayName, value: item._id }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh-left" },
                          on: {
                            click: function($event) {
                              return _vm.resetForm("listQuery")
                            }
                          }
                        },
                        [_vm._v("重置")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { marginTop: "20px" },
                          attrs: {
                            type: "success",
                            icon: "el-icon-circle-plus-outline"
                          },
                          on: { click: _vm.handleAddKol }
                        },
                        [_vm._v("新增")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.handleFilter }
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: {
                            loading: _vm.downloadLoading,
                            type: "primary",
                            icon: "el-icon-document"
                          },
                          on: { click: _vm.handleDownload }
                        },
                        [_vm._v("\n          导出\n        ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ]
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: {
                            loading: _vm.emailLoadLoading,
                            type: "primary",
                            icon: "el-icon-document"
                          },
                          on: { click: _vm.handleEmail }
                        },
                        [_vm._v("\n          邮件邀请\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.message,
                              expression: "message",
                              arg: "copy"
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:success",
                              value: _vm.onCopy,
                              expression: "onCopy",
                              arg: "success"
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:error",
                              value: _vm.onError,
                              expression: "onError",
                              arg: "error"
                            }
                          ],
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: { type: "success", icon: "el-icon-document" }
                        },
                        [_vm._v("\n          复制链接邀请红人入驻\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  ref: "multipleTable",
                  staticClass: "table-wrap",
                  attrs: {
                    data: _vm.list,
                    border: "",
                    stripe: "",
                    fit: "",
                    "highlight-current-row": ""
                  },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", align: "center" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "KOL昵称", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.kolInfo.navDisplayName))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3686356778
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "区域", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.kolInfo.area
                                ? _c("span", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(scope.row.kolInfo.area) +
                                        "\n          "
                                    )
                                  ])
                                : _c("span", [_vm._v("--")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      597609649
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "行业类型", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.kolInfo.industries &&
                              row.kolInfo.industries.length > 0
                                ? _c(
                                    "div",
                                    _vm._l(row.kolInfo.industries, function(
                                      item
                                    ) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: item,
                                          staticClass: "mr-8 mt-8",
                                          attrs: { type: "success" }
                                        },
                                        [_vm._v(_vm._s(item))]
                                      )
                                    }),
                                    1
                                  )
                                : _c("div", [_vm._v("--")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3287277908
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "社交媒体平台", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.kolInfo.platforms &&
                              scope.row.kolInfo.platforms.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "platform-wrap" },
                                    _vm._l(
                                      scope.row.kolInfo.platformKol,
                                      function(item) {
                                        return _c("span", { key: item.link }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: item.platformLink,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticClass: "mr-8",
                                                attrs: {
                                                  "icon-class": item.platform
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _c("div", [_vm._v("--")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2688665876
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "联系方式", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("div", [
                                row.kolInfo.navEmail
                                  ? _c("span", [
                                      _vm._v(_vm._s(row.kolInfo.navEmail))
                                    ])
                                  : _c("span", [_vm._v("--")]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                row.kolInfo.navMobile
                                  ? _c("span", [
                                      _vm._v(_vm._s(row.kolInfo.navMobile))
                                    ])
                                  : _c("span", [_vm._v("--")])
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      92858207
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "来源渠道", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.idCampaign
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-tag",
                                        { staticClass: "mr-8 mt-8" },
                                        [_vm._v("活动邀请")]
                                      )
                                    ],
                                    1
                                  )
                                : _c("div", [_vm._v("--")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      857331029
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      "class-name": "status-col",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatUTCDate")(
                                      row.createAt,
                                      "YYYY-MM-DD"
                                    )
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      62638965
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "跟进状态", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.status
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        trigger: "hover",
                                        content: row.remark,
                                        placement: "top-start"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: _vm.comStatusTag(row.status)
                                          },
                                          slot: "reference"
                                        },
                                        [_vm._v(_vm._s(row.status))]
                                      )
                                    ],
                                    1
                                  )
                                : _c("span", [_vm._v("--")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1940999762
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "跟进人", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.assignedTo
                                ? _c("span", [
                                    _vm._v(_vm._s(row.assignedTo.name))
                                  ])
                                : _c("span", [_vm._v("--")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      542883710
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "优先级", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.priority
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: _vm.comPriority(row.priority).type
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.comPriority(row.priority).name
                                        )
                                      )
                                    ]
                                  )
                                : _c("span", [_vm._v("--")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1875036944
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "min-width": "80",
                      "class-name": "small-padding fixed-width"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              !row.assignedTo
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: !row.kolInfo.navEmail
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.claimTicket(row)
                                        }
                                      }
                                    },
                                    [_vm._v("认领")]
                                  )
                                : _c(
                                    "el-button-group",
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                          icon: "el-icon-folder-opened"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleChasingLead(row)
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("el-button", {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                          icon: "el-icon-s-promotion"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openMailWindow(row)
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("el-button", {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                          icon: "el-icon-edit"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleEditKOL(row)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3830958140
                    )
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0"
                  }
                ],
                attrs: { total: _vm.total, page: _vm.page, limit: _vm.limit },
                on: {
                  "update:page": function($event) {
                    _vm.page = $event
                  },
                  "update:limit": [
                    function($event) {
                      _vm.limit = $event
                    },
                    _vm.updateLimit
                  ],
                  pagination: _vm.getList
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "邮件邀请确认",
            visible: _vm.emailDialog,
            width: "960px",
            center: "",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.emailDialog = $event
            }
          }
        },
        [
          _c("email-tpl", {
            attrs: { "email-object": _vm.emailObject },
            on: { sendEmail: _vm.sendEmail }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }