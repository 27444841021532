"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  language: function language(state) {
    return state.app.language;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  addRoutes: function addRoutes(state) {
    return state.user.addRoutes;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  user: function user(state) {
    return state.user;
  },
  name: function name(state) {
    return state.user.name;
  },
  uid: function uid(state) {
    return state.user.uid;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  }
};
var _default = getters;
exports.default = _default;