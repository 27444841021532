"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortedUniq = sortedUniq;
exports._sort = _sort;
exports.bloomFilter = bloomFilter;
exports.currencyFilter = currencyFilter;
exports.formatUTCDate = formatUTCDate;
exports.ModalHelper = void 0;

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.array.sort");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.set");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

/**
 * @param {Array}
 * @returns {Array}
 */
function sortedUniq(ArrayData) {
  return Array.from(new Set(ArrayData));
}
/**
 * @param {Array}
 * @returns {Array}
 */


function _sort(arr) {
  return arr.sort(function (a, b) {
    return a - b;
  });
}
/** *
 * 币种过滤器
 */


function bloomFilter(value, type) {
  var filterString = '';

  switch (type) {
    case 'cny':
      filterString = '¥' + value;
      break;

    case 'usd':
      filterString = '$' + value;
      break;

    default:
      filterString = '¥' + value;
  }

  return filterString;
}
/** *
 * 币种过滤器
 */


function currencyFilter(type) {
  var filterString = '';

  switch (type) {
    case 'cny':
      filterString = '¥';
      break;

    case 'usd':
      filterString = '$';
      break;

    case 'eur':
      filterString = '€';
      break;

    case 'aud':
      filterString = '$A';
      break;

    default:
      filterString = '¥';
  }

  return filterString;
}
/**
 * 日期过滤
 * @param { timeStamp } timeStamp
 * @param { String } type 'YYYY-MM-DD hh:mm:ss'
 */


function formatUTCDate(timeStamp) {
  var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD hh:mm:ss';
  var date = new Date(timeStamp);

  if (/(Y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }

  var obj = {
    'M+': '0' + (date.getMonth() + 1),
    'D+': '0' + date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };

  for (var key in obj) {
    if (new RegExp("(".concat(key, ")")).test(fmt)) {
      var value = obj[key] + '';
      var str = value.substring(value.length - 2, value.length);
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 || RegExp.$1.length === 2 ? str : this.padLeftZero(str));
    }
  }

  return fmt;
}
/** *
 * 解决弹出框穿透问题
 */


var ModalHelper = function (bodyCls) {
  var scrollTop;
  return {
    afterOpen: function afterOpen() {
      scrollTop = document.scrollingElement.scrollTop;
      document.body.classList.add(bodyCls);
      document.body.style.top = -scrollTop + 'px';
    },
    beforeClose: function beforeClose() {
      document.body.classList.remove(bodyCls); // scrollTop lost after set position:fixed, restore it back.

      document.scrollingElement.scrollTop = scrollTop;
    }
  };
}('modal-open');

exports.ModalHelper = ModalHelper;