"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.getInfo = getInfo;
exports.getAdminUsers = getAdminUsers;
exports.createAdminUser = createAdminUser;
exports.updateAdminUser = updateAdminUser;
exports.logout = logout;

var _request = _interopRequireDefault(require("@/utils/request"));

// 登陆
function login(data) {
  return (0, _request.default)({
    url: '/authentication',
    method: 'post',
    data: data
  });
} // 获取单个用户信息


function getInfo(uid) {
  return (0, _request.default)({
    url: "/admins/".concat(uid),
    method: 'get'
  });
}

function getAdminUsers(params) {
  return (0, _request.default)({
    url: '/admins',
    method: 'get',
    params: params
  });
}

function createAdminUser(data) {
  return (0, _request.default)({
    url: '/admins',
    method: 'post',
    data: data
  });
}

function updateAdminUser(id, data) {
  return (0, _request.default)({
    url: "/admins/".concat(id),
    method: 'patch',
    data: data
  });
} // 登出


function logout() {
  return (0, _request.default)({
    url: '/authentication',
    method: 'delete'
  });
}