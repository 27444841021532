var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base-info" }, [
    _c(
      "section",
      { staticClass: "table-item" },
      [
        _c("h3", { staticClass: "title" }, [_vm._v("联系人信息")]),
        _vm._v(" "),
        _c(
          "el-table",
          { attrs: { data: _vm.baseData, border: "" } },
          [
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "name",
                label: "联系人",
                "min-width": "180"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "email",
                label: "邮箱",
                "min-width": "180"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "mobile",
                label: "联系电话",
                "min-width": "180"
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "table-item" },
      [
        _c("h3", { staticClass: "title" }, [_vm._v("企业信息")]),
        _vm._v(" "),
        _c(
          "el-table",
          { attrs: { data: _vm.companyData, border: "" } },
          [
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "brandName",
                label: "企业名字",
                "min-width": "100"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "企业类型", "min-width": "80", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      row.type
                        ? _c("div", [
                            row.type === "brand"
                              ? _c("span", [_vm._v("品牌方")])
                              : _c("span", [_vm._v("广告代理商")])
                          ])
                        : _vm._e()
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "企业国家", "min-width": "60", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      row.area[0]
                        ? _c("span", [_vm._v(_vm._s(_vm.comArea(row.area[0])))])
                        : _c("span", [_vm._v("--")])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "企业官方网站",
                "min-width": "180",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      row.website
                        ? _c("span", [_vm._v(_vm._s(row.website))])
                        : _c("span", [_vm._v("--")])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "关注行业",
                "min-width": "160",
                align: "center",
                "class-name": "industries"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return _vm._l(_vm.comIndustries(row.industries), function(
                      item
                    ) {
                      return _c(
                        "el-tag",
                        {
                          key: item,
                          staticClass: "mr-8 mt-8",
                          attrs: { type: "success" }
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    })
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "table-item" },
      [
        _c("h3", { staticClass: "title" }, [_vm._v("订阅信息")]),
        _vm._v(" "),
        _c(
          "el-table",
          { attrs: { data: _vm.subscribeData, border: "" } },
          [
            _c("el-table-column", {
              attrs: {
                label: "推荐人",
                "class-name": "status-col",
                "min-width": "180",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      row.referral
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.comReferrer(row.referral)))
                          ])
                        : _c("span", [_vm._v("--")])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "注册时间",
                "class-name": "status-col",
                "min-width": "180",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatUTCDate")(row.createAt, "YYYY-MM-DD")
                          )
                        )
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }