"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _global = _interopRequireDefault(require("@/mixin/global"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  mixins: [_global.default],
  data: function data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      loginRules: {
        email: [{
          type: 'email',
          required: true,
          trigger: ['change', 'blur'],
          message: '请输入有效邮箱'
        }],
        password: [{
          type: 'string',
          required: true,
          trigger: ['change', 'blur'],
          message: '请输入密码'
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false
    };
  },
  mounted: function mounted() {
    if (this.loginForm.email === '') {
      this.$refs.email.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  methods: {
    checkCapslock: function checkCapslock() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          shiftKey = _ref.shiftKey,
          key = _ref.key;

      if (key && key.length === 1) {
        if (shiftKey && key >= 'a' && key <= 'z' || !shiftKey && key >= 'A' && key <= 'Z') {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }

      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    showPwd: function showPwd() {
      var _this = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;

          _this2.$store.dispatch('user/login', _this2.loginForm).then(function () {
            console.log('成功');

            _this2.fetchOptions().then(function () {
              _this2.$router.push('/kol/intake');

              _this2.loading = false;
            }).catch(function () {
              _this2.loading = false;
            });
          }).catch(function () {
            _this2.loading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;