"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSupportTickets = getSupportTickets;
exports.updateSupportTicket = updateSupportTicket;
exports.sendMail = sendMail;

var _request = _interopRequireDefault(require("@/utils/request"));

// List aa support tickets
function getSupportTickets() {
  return (0, _request.default)({
    url: '/support-tickets',
    method: 'get'
  });
}

function updateSupportTicket(ticketId, data) {
  return (0, _request.default)({
    url: "/support-tickets/".concat(ticketId),
    method: 'patch',
    data: data
  });
}

function sendMail(data, action) {
  return (0, _request.default)({
    url: "/mailer",
    method: 'post',
    data: data
  });
}