"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _api = _interopRequireDefault(require("@/api"));

var _config = _interopRequireDefault(require("@/config/config"));

var _table = _interopRequireDefault(require("@/mixin/table"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var listQuery = {
  createdAtDate: undefined,
  updatedAtDate: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  status: undefined,
  payeeAccount: undefined,
  payeeName: undefined
};
var _default = {
  name: 'Withdrawals',
  mixins: [_table.default],
  data: function data() {
    return {
      listQuery: this.$_.clone(listQuery),
      page: 1,
      limit: 10,
      total: 0,
      statusOptions: [{
        name: '成功',
        value: 'success'
      }, {
        name: '待审核',
        value: 'pending'
      }, {
        name: '审核中',
        value: 'approved'
      }, {
        name: '已驳回',
        value: 'rejected'
      }, {
        name: '失败',
        value: 'failed'
      }],
      list: [],
      listLoading: false,
      pickerOptions: _config.default.pickerOptions,
      date: null,
      updateDate: null,
      ids: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['uid'])),
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    translateProvider: function translateProvider(provider) {
      var providerMap = {
        alipay: '支付宝',
        stripe: 'Stripe'
      };
      return providerMap[provider];
    },
    translateStatus: function translateStatus(status) {
      var statusMap = {
        success: '成功',
        failed: '失败',
        pending: '待审核',
        rejected: '已驳回',
        approved: '审核中'
      };
      return statusMap[status];
    },
    handleCreatedAtDate: function handleCreatedAtDate(data) {
      if (Array.isArray(data) && data.every(function (item) {
        return item instanceof Date;
      })) {
        this.listQuery.createdAt = {
          $gt: this.formatUtcDate(data[0]),
          $lt: this.formatUtcDate(data[1])
        };
      } else {
        this.listQuery.createdAt = undefined;
      }
    },
    handleUpdatedAtDate: function handleUpdatedAtDate(data) {
      if (Array.isArray(data) && data.every(function (item) {
        return item instanceof Date;
      })) {
        this.listQuery.updatedAt = {
          $gt: this.formatUtcDate(data[0]),
          $lt: this.formatUtcDate(data[1])
        };
      } else {
        this.listQuery.updatedAt = undefined;
      }
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        payeeAccount: this.listQuery.payeeAccount || undefined,
        payeeName: this.listQuery.payeeName || undefined,
        '$skip': (this.page - 1) * this.limit,
        '$limit': this.limit
      });
      delete params.createdAtDate;
      delete params.updatedAtDate;
      this.listLoading = true;

      _api.default.getWithdrawals(params).then(function (res) {
        _this.list = res.data;
        _this.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.ids = val.map(function (item) {
        return item._id;
      });
    },
    handleRefund: function handleRefund(row) {
      var _this2 = this;

      var params = {
        status: 'approved',
        idUser: row.idUser,
        idWithdrawal: row._id,
        idOperatorUser: this.uid
      };
      this.$confirm('系统将给用户出款，此操作不可逆转, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.handleChangeWithdrawal(params);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消出款'
        });
      });
    },
    handleDispensing: function handleDispensing(row) {
      var _this3 = this;

      var params = {
        status: 'rejected',
        idUser: row.idUser,
        idWithdrawal: row._id,
        idOperatorUser: this.uid
      };
      this.$confirm('系统将驳回此次提现申请，此操作不可逆转, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.handleChangeWithdrawal(params);
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleChangeWithdrawal: function handleChangeWithdrawal(params) {
      var _this4 = this;

      _api.default.updateWithdrawal(params).then(function (res) {
        _this4.getList();
      }).catch(function (e) {
        _this4.getList();
      });
    },
    resetForm: function resetForm() {
      this.listQuery = this.$_.clone(listQuery);
      this.date = null;
      this.updateDate = null;
      this.handleFilter();
    }
  }
};
exports.default = _default;