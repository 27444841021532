var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tags-view-container",
      attrs: { id: "tags-view-container" }
    },
    [
      _c(
        "scroll-pane",
        { ref: "scrollPane", staticClass: "tags-view-wrapper" },
        _vm._l(_vm.visitedViews, function(tag) {
          return _c(
            "router-link",
            {
              key: tag.path,
              ref: "tag",
              refInFor: true,
              staticClass: "tags-view-item",
              class: _vm.isActive(tag) ? "active" : "",
              attrs: {
                to: {
                  path: tag.path,
                  query: tag.query,
                  fullPath: tag.fullPath
                },
                tag: "span"
              },
              nativeOn: {
                mouseup: function($event) {
                  if ("button" in $event && $event.button !== 1) {
                    return null
                  }
                  return _vm.closeSelectedTag(tag)
                },
                contextmenu: function($event) {
                  $event.preventDefault()
                  return _vm.openMenu(tag, $event)
                }
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.generateTitle(tag.title)) + "\n      "
              ),
              !tag.meta.affix
                ? _c("span", {
                    staticClass: "el-icon-close",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.closeSelectedTag(tag)
                      }
                    }
                  })
                : _vm._e()
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }