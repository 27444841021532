"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CreateUserDialog',
  props: {
    roleOptions: {
      type: Array,
      default: undefined
    }
  },
  data: function data() {
    return {
      passwordType: 'password',
      capsTooltip: false,
      visible: true,
      dirty: false,
      loading: false,
      createUserForm: {
        email: '',
        password: '',
        name: '',
        roles: [],
        active: true
      },
      createUserRules: {
        email: [{
          type: 'email',
          required: true,
          trigger: ['change', 'blur'],
          message: '请输入邮箱',
          transform: function transform(value) {
            return value.trim();
          }
        }],
        password: [{
          type: 'string',
          required: false,
          trigger: ['change', 'blur'],
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$/,
          message: '密码为8-16位数字加字母大小写的组合',
          transform: function transform(value) {
            return value.trim();
          },
          validator: function validator(rule, value, callback) {
            if (!rule.pattern.test(value)) {
              callback(new Error(rule.message));
            } else {
              callback();
            }
          }
        }],
        name: [{
          type: 'string',
          required: true,
          trigger: ['change', 'blur'],
          message: '请输入姓名',
          transform: function transform(value) {
            return value.trim();
          }
        }],
        roles: [{
          type: 'array',
          required: true,
          trigger: 'change',
          message: '请至少选择一个角色'
        }]
      }
    };
  },
  methods: {
    handleClose: function handleClose(done) {
      var _this = this;

      if (this.dirty) {
        this.$confirm('确定取消所做的更改么？', {
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        }).then(function () {
          _this.resetForm();

          _this.$emit('closeDialog');

          if (done) done();
        }).catch(function () {});
      } else {
        this.resetForm();
        this.$emit('closeDialog');
        if (done) done();
      }
    },
    handleInputChange: function handleInputChange() {
      this.dirty = true;
    },
    resetForm: function resetForm() {
      this.$refs.createUserForm.resetFields();
      this.dirty = false;
    },
    checkCapslock: function checkCapslock() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          shiftKey = _ref.shiftKey,
          key = _ref.key;

      if (key && key.length === 1) {
        if (shiftKey && key >= 'a' && key <= 'z' || !shiftKey && key >= 'A' && key <= 'Z') {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }

      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    showPwd: function showPwd() {
      var _this2 = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    handleUpdateUser: function handleUpdateUser() {
      var _this3 = this;

      if (!this.dirty) {
        this.$emit('closeDialog');
        return true;
      }

      this.$refs.createUserForm.validate(function (valid) {
        if (valid) {
          _this3.loading = true;

          _api.default.createAdminUser({
            email: _this3.createUserForm.email,
            password: _this3.createUserForm.password || undefined,
            name: _this3.createUserForm.name || undefined,
            roles: _this3.createUserForm.roles,
            active: _this3.createUserForm.active
          }).then(function () {
            _this3.loading = false;

            _this3.resetForm();

            _this3.$emit('userCreated');

            _this3.$emit('closeDialog');
          }).catch(function () {
            _this3.loading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;