"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _moment = _interopRequireDefault(require("moment"));

var _default = {
  install: function install(Vue) {
    var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '$moment';
    Object.defineProperty(Vue.prototype, name, {
      value: _moment.default
    });
  }
};
exports.default = _default;