"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _table = _interopRequireDefault(require("@/mixin/table"));

var _editRoleDialog = _interopRequireDefault(require("./components/edit-role-dialog"));

var _createRoleDialog = _interopRequireDefault(require("./components/create-role-dialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var listQuery = {
  keyword: undefined
};
var _default = {
  name: 'ManageRoles',
  components: {
    EditRoleDialog: _editRoleDialog.default,
    CreateRoleDialog: _createRoleDialog.default
  },
  mixins: [_table.default],
  data: function data() {
    return {
      listQuery: this.$_.cloneDeep(listQuery),
      editRoleDialogVisible: false,
      editRole: undefined,
      createRoleDialogVisible: false,
      permissionDataLoading: false,
      permissionsTree: undefined
    };
  },
  computed: {
    showPage: function showPage() {
      return this.$route.name === 'ManageRoles';
    }
  },
  created: function created() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      if (this.showPage) {
        this.getList();
        this.getPermissionData();
      }
    },
    getPermissionData: function getPermissionData() {
      var _this = this;

      this.permissionDataLoading = true;

      _api.default.getPermissions({
        tree: true
      }).then(function (permissions) {
        _this.permissionDataLoading = false;
        _this.permissionsTree = permissions;
      }).catch(function () {
        _this.permissionDataLoading = false;
      });
    },
    getList: function getList() {
      var _this2 = this;

      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        keyword: this.listQuery.keyword ? this.listQuery.keyword.trim() : undefined,
        '$skip': (this.page - 1) * this.limit,
        '$limit': this.limit
      });
      this.listLoading = true;

      _api.default.getRoles(params).then(function (res) {
        _this2.list = res.data;
        _this2.total = res.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    handleEdit: function handleEdit(role) {
      this.editRole = this.$_.cloneDeep(role);
      this.editRoleDialogVisible = true;
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.listQuery = this.$_.cloneDeep(listQuery);
      this.handleFilter();
    },
    handleCloseEditRoleDialog: function handleCloseEditRoleDialog() {
      this.editRoleDialogVisible = false;
    },
    handleCreateRole: function handleCreateRole() {
      this.createRoleDialogVisible = true;
    },
    handleCloseCreateRoleDialog: function handleCloseCreateRoleDialog() {
      this.createRoleDialogVisible = false;
    },
    filterRolePermissions: function filterRolePermissions(rolePermissions) {
      var _this3 = this;

      var permissionsTree = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.permissionsTree;
      if (!permissionsTree) return [];
      var result = [];
      var rolePermissionIds = rolePermissions.map(function (p) {
        return p._id;
      }); // The logic must be like this: if every child is checked, just show the
      // parent name, otherwise just show the child names.

      permissionsTree.forEach(function (permission) {
        // If it's a single node without children then check if it's checked.
        //
        // PermissionsTree {
        //   node1 <-- single node without children
        //   node2 <-- single node without children
        //   node3
        //     node3.1
        //     node3.2
        // }
        if (permission.children.length === 0) {
          var isChecked = rolePermissionIds.includes(permission._id);

          if (isChecked) {
            result.push(permission);
          }
        } else {
          // Get the child nodes that are checked. If all of them are checked
          // then we only display their parent node's name.
          //
          // PermissionsTree {
          //   [ ] node1
          //   [ ] node2
          //   [x] node3 <-- display this node's name because all childs are checked
          //     [x] node3.1 <-- checked
          //     [x] node3.2 <-- checked
          // }
          var checkedChildNodes = _this3.filterRolePermissions(rolePermissions, permission.children);

          if (checkedChildNodes.length === permission.children.length) {
            result.push(permission);
          } else {
            result = [].concat((0, _toConsumableArray2.default)(result), (0, _toConsumableArray2.default)(checkedChildNodes));
          }
        }
      });
      return result;
    }
  }
};
exports.default = _default;