"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _api = _interopRequireDefault(require("@/api"));

var _config = _interopRequireDefault(require("@/config/config"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var listQuery = {
  createdAtStart: undefined,
  createdAtEnd: undefined,
  status: undefined,
  platform: undefined
};
var _default = {
  name: 'KolAudit',
  filters: {
    statusFilter: function statusFilter(val) {
      var mapKey = {
        pending: '待审核',
        inProgress: '处理中',
        approved: '已通过',
        reject: '已驳回'
      };
      return mapKey[val];
    }
  },
  data: function data() {
    var platformOptions = this.$storage.get('platformOptions');
    return {
      listQuery: this.$_.clone(listQuery),
      page: 1,
      limit: 10,
      total: 0,
      list: [],
      listLoading: false,
      pickerOptions: _config.default.pickerOptions,
      date: null,
      ids: [],
      platformOptions: Object.keys(platformOptions).map(function (key) {
        return {
          value: key,
          name: platformOptions[key]
        };
      }),
      statusOptions: [{
        name: '待审核',
        value: 'pending'
      }, {
        name: '处理中',
        value: 'inProgress'
      }, {
        name: '已通过',
        value: 'approved'
      }, {
        name: '已驳回',
        value: 'reject'
      }]
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    formatUTCDate: function formatUTCDate(utc_datetime) {
      var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD';
      return this.$moment(utc_datetime).format(fmt);
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    handleDate: function handleDate(row) {
      if (row) {
        this.listQuery.createdAtStart = this.formatUTCDate(this.date[0]);
        this.listQuery.createdAtEnd = this.formatUTCDate(this.date[1]);
      } else {
        this.listQuery.createdAtStart = undefined;
        this.listQuery.createdAtEnd = undefined;
      }
    },
    getList: function getList() {
      var _this = this;

      var params = {
        'createdAt[$gte]': this.date ? this.date[0] : undefined,
        'createdAt[$lte]': this.date ? this.date[1] : undefined,
        status: this.listQuery.status ? this.listQuery.status : undefined,
        'platformInfo.platform': this.listQuery.platform ? this.listQuery.platform : {
          $in: this.platformOptions.map(function (option) {
            return option.value;
          })
        },
        '$skip': (this.page - 1) * this.limit,
        '$limit': this.limit,
        'type': 'bind'
      };
      this.listLoading = true;

      _api.default.getWorkorders(params).then(function (res) {
        _this.list = res.data;
        _this.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.ids = val.map(function (item) {
        return item._id;
      });
      console.log(this.ids);
    },
    handleDispensing: function handleDispensing(row) {
      var _this2 = this;

      var params = {
        'status': 'inProgress',
        'idWorkorder': row._id
      };
      this.$confirm('是否确认通过平台审核?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.handleChangeWithwral(row._id, params);
      });
    },
    handleRefund: function handleRefund(row) {
      var _this3 = this;

      this.$prompt('请输入驳回理由', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (_ref) {
        var value = _ref.value;

        if (!value) {
          _this3.$message.error('请输入驳回理由');

          return;
        }

        var params = {
          'status': 'reject',
          'reason': value,
          'idWorkorder': row._id
        };

        _this3.handleChangeWithwral(row._id, params);
      });
    },
    handleChangeWithwral: function handleChangeWithwral(id, params) {
      var _this4 = this;

      _api.default.updateWorkorders(id, params).then(function (res) {
        _this4.getList();
      });
    },
    resetForm: function resetForm() {
      this.listQuery = this.$_.clone(listQuery);
      this.date = null;
      this.handleFilter();
    }
  }
};
exports.default = _default;