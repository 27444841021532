"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EditDiv',
  props: {
    value: {
      type: String,
      default: ''
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      innerText: this.value,
      isLocked: false
    };
  },
  methods: {
    changeText: function changeText() {
      this.$emit('update-body', this.$el.innerHTML);
    }
  }
};
exports.default = _default;