var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "cooperation-header" },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "div",
                { staticClass: "cooperation-name" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.getTitle.name))]),
                  _vm._v(" "),
                  _c(
                    "el-tag",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: {
                        type: _vm.comCampaignsStatus(_vm.getTitle.status)
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("activeState")(_vm.getTitle.status)) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 15 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _vm._v(
                      "\n          发布时间：" +
                        _vm._s(
                          _vm._f("formatUTCLocalDate")(_vm.getTitle.startDate)
                        ) +
                        " ~\n          " +
                        _vm._s(
                          _vm._f("formatUTCLocalDate")(_vm.getTitle.endDate)
                        ) +
                        "  " +
                        _vm._s(_vm.getTitle.isPrivate) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "cooperation-title" },
        [
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "cooperation-num" }, [
              _vm._v(_vm._s(_vm.getCooperationList.pending))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-10" },
              [
                _vm._v("\n        合作确认\n        "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "top-start" }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "\n            品牌需要确认是否与提交合作申请的红人合作。"
                      ),
                      _c("br"),
                      _vm._v("若品牌拒绝申请，则这个合作失效。\n          ")
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-warning-outline" })
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "cooperation-num" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.getCooperationList.packageNotSent) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-10" },
              [
                _vm._v("\n        样品发放\n        "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "top-start" }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v("\n            品牌和红人建立合作后，"),
                      _c("br"),
                      _vm._v("品牌需要尽快将待测评的产品发放给红人，"),
                      _c("br"),
                      _vm._v("提供样品的方式有两种："),
                      _c("br"),
                      _vm._v("直接寄送并填写物流单号，"),
                      _c("br"),
                      _vm._v("或者告诉红人获取方式。\n          ")
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-warning-outline" })
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "cooperation-num" }, [
              _vm._v(_vm._s(_vm.getCooperationList.packageSent))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-10" },
              [
                _vm._v("\n        推文发布\n        "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "top-start" }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v("等待红人接收样品，然后按照要求发布推文")
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-warning-outline" })
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "cooperation-num" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.getCooperationList.pendingPayment) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-10" },
              [
                _vm._v("\n        品牌验收\n        "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "",
                      placement: "top-start"
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "\n            红人已经发布推文，等待品牌方验收内容。"
                      ),
                      _c("br"),
                      _vm._v(
                        "如果这是一个报价测评，需要支付红人报酬。\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-warning-outline" })
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "cooperation-num" }, [
              _vm._v(_vm._s(_vm.getCooperationList.paid))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-10" },
              [
                _vm._v("\n        合作完成\n        "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "Top Left 提示文字",
                      placement: "top-start"
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v("品牌验收红人的推文后，代表这个合作已经完成")
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-warning-outline" })
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "cooperation-price" }, [
              _vm._v(
                "\n        $ " +
                  _vm._s(_vm.getCooperationList.paidQuote) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-10" }, [_vm._v("已结算费用")])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "cooperation-select" },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.handleFilter },
                  model: {
                    value: _vm.value,
                    callback: function($$v) {
                      _vm.value = $$v
                    },
                    expression: "value"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "cooperation-total", attrs: { span: 4, offset: 1 } },
            [_vm._v("总共：" + _vm._s(_vm.total))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "table-wrap",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            stripe: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "红人", align: "left", fixed: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.idUser.name))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "报价", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [_vm._v(_vm._s(_vm._f("toFixed2")(row.quote)))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.status))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "发帖用时",
              align: "center",
              "render-header": _vm.renderTipsHeader
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.duration != "--"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$moment.duration(row.duration).days() +
                                "天" +
                                _vm.$moment.duration(row.duration).hours() +
                                "小时" +
                                _vm.$moment.duration(row.duration).minutes() +
                                "分"
                            )
                          )
                        ])
                      : _c("span", [_vm._v("--")])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "总曝光量",
              align: "center",
              sortable: "",
              prop: "budget"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("numberFormatter")(row.exposure)))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "媒体价值",
              align: "center",
              sortable: "",
              prop: "budget"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("numberFormatter")(row.postMediaValue))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "ROI",
              align: "center",
              "render-header": _vm.renderROIHeader
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.ROI))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "合作评价",
              align: "center",
              "render-header": _vm.renderRateHeader
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.review
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(row.review.efficiency) +
                              "/" +
                              _vm._s(row.review.contentQuality) +
                              "/" +
                              _vm._s(row.review.satisfaction) +
                              "\n        "
                          )
                        ])
                      : _c("span", [_vm._v("--")])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: { total: _vm.total, page: _vm.page, limit: _vm.limit },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:limit": function($event) {
            _vm.limit = $event
          },
          pagination: _vm.getJobs
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }