"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.asyncRoutes = exports.constantRoutes = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

_vue.default.use(_vueRouter.default); // 固定路由表


var constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true,
  filter: true,
  meta: {
    title: '登陆'
  }
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/404'));
    });
  },
  hidden: true,
  filter: true,
  meta: {
    title: '404'
  }
}];
/**
 * 动态路由表
 */

exports.constantRoutes = constantRoutes;
var asyncRoutes = [// kol管理
{
  path: '/kol',
  component: 'Layout',
  redirect: '/kol/intake',
  alwaysShow: true,
  filter: false,
  breadcrumb: false,
  meta: {
    title: '红人管理',
    icon: 'kol'
  },
  permissions: ['manage_influencers'],
  children: [{
    path: 'intake',
    component: 'views/kol/intake/index',
    name: 'KolIntake',
    meta: {
      title: '已入驻'
    },
    children: [{
      path: 'detail/:id',
      component: 'views/kol/intake/children/detail/index',
      name: 'KolDetail',
      hidden: true,
      filter: false,
      meta: {
        title: '基本信息'
      }
    }]
  }]
}, // 品牌管理
{
  path: '/brand',
  component: 'Layout',
  redirect: '/brand/list',
  filter: false,
  alwaysShow: true,
  meta: {
    title: '品牌管理',
    icon: 'brand'
  },
  permissions: ['manage_brands'],
  children: [{
    path: 'list',
    component: 'views/brand/list/index',
    name: 'BrandList',
    filter: false,
    meta: {
      title: '品牌列表'
    },
    children: [{
      path: 'detail/:id',
      component: 'views/brand/list/children/detail/index',
      name: 'BrandDetail',
      hidden: true,
      filter: false,
      meta: {
        title: '品牌详情'
      }
    }]
  }]
}, // 活动管理
{
  path: '/campaigns',
  component: 'Layout',
  redirect: '/campaigns/list',
  alwaysShow: true,
  filter: false,
  meta: {
    title: '活动管理',
    icon: 'campaigns'
  },
  permissions: ['manage_campaigns'],
  children: [{
    path: 'list',
    component: 'views/campaigns/list/index',
    name: 'CampaignsList',
    filter: false,
    meta: {
      title: '活动列表'
    }
  }, {
    path: 'campaigns-detail',
    component: 'views/campaigns-detail/index',
    name: 'campaignsDetail',
    filter: false,
    hidden: true,
    meta: {
      title: '活动详情'
    }
  }, {
    path: 'cooperation',
    component: 'views/cooperation/index',
    name: 'cooperation',
    filter: false,
    hidden: true,
    meta: {
      title: '合作情况'
    }
  }]
}, // 系统管理
{
  path: '/system',
  component: 'Layout',
  redirect: '/system/users',
  filter: false,
  alwaysShow: true,
  meta: {
    title: '系统管理',
    icon: 'checkmark'
  },
  permissions: ['manage_systems-manage_roles', 'manage_systems-manage_users'],
  children: [{
    path: 'users',
    component: 'views/system/users/index',
    name: 'ManageUsers',
    meta: {
      title: '用户管理'
    },
    permissions: ['manage_systems-manage_users']
  }, {
    path: 'roles',
    component: 'views/system/roles/index',
    name: 'ManageRoles',
    meta: {
      title: '角色管理'
    },
    permissions: ['manage_systems-manage_roles']
  }]
}, // 财务
{
  path: '/finance',
  component: 'Layout',
  redirect: '/finance/withdrawals/index',
  alwaysShow: true,
  filter: false,
  meta: {
    title: '财务管理',
    icon: 'calculator'
  },
  permissions: ['manage_financials-manage_withdrawals', 'manage_financials-manage_recharges'],
  children: [{
    path: 'withdrawals',
    component: 'views/finance/withdrawals/index',
    name: 'Withdrawals',
    filter: false,
    meta: {
      title: '提现管理'
    },
    permissions: ['manage_financials-manage_withdrawals']
  }, {
    path: 'recharges',
    component: 'views/finance/recharges/index',
    name: 'Recharges',
    filter: false,
    meta: {
      title: '充值管理'
    },
    permissions: ['manage_financials-manage_recharges']
  }]
}, // 审核管理
{
  path: '/audit',
  component: 'Layout',
  redirect: '/audit/kol/index',
  alwaysShow: true,
  filter: false,
  permissions: ['manage_audits-review_influencers'],
  meta: {
    title: '审核管理',
    icon: 'audit'
  },
  children: [{
    path: 'kol',
    component: 'views/audit/kol/index',
    name: 'auditKol',
    filter: false,
    meta: {
      title: '红人审核'
    },
    permissions: ['manage_audits-review_influencers']
  }]
}, {
  path: '/',
  redirect: '/kol',
  hidden: true,
  filter: true
}, {
  path: '*',
  redirect: '/404',
  hidden: true,
  filter: true
}];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter();

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;