"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRoutes = getRoutes;
exports.getRoles = getRoles;
exports.createRole = createRole;
exports.updateRole = updateRole;

var _request = _interopRequireDefault(require("@/utils/request"));

function getRoutes() {
  return (0, _request.default)({
    url: '/routes',
    method: 'get'
  });
}

function getRoles(params) {
  return (0, _request.default)({
    url: '/admin-roles',
    method: 'get',
    params: params
  });
}

function createRole(data) {
  return (0, _request.default)({
    url: '/admin-roles',
    method: 'post',
    data: data
  });
}

function updateRole(id, data) {
  return (0, _request.default)({
    url: "/admin-roles/".concat(id),
    method: 'patch',
    data: data
  });
}