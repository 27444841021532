"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _auth = require("@/utils/auth");

var _index = require("@/lang/index");

function MessageTip(_ref) {
  var _ref$type = _ref.type,
      type = _ref$type === void 0 ? 'error' : _ref$type,
      message = _ref.message;

  if (message) {
    (0, _elementUi.Message)({
      message: message,
      type: type
    });
  }
} // create an axios instance


var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 40 * 1000 // request timeout

});

service.interceptors.request.use(function (config) {
  config.headers['language'] = (0, _index.getLanguage)();

  if (_store.default.getters.token) {
    config.headers['Authorization'] = (0, _auth.getToken)();
  }

  return config;
}, function (error) {
  console.log(error, '请求出错'); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(function (response) {
  var res = response.data || response.data;

  if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 203 || response.status === 205) {
    return res;
  }
}, function (error) {
  if (error && error.response) {
    var message = error.response.data.message;

    switch (error.response.data.code) {
      case 400:
        MessageTip({
          message: message
        });
        break;

      case 401:
        MessageTip({
          message: message
        });
        break;

      case 402:
        MessageTip({
          message: message
        });
        break;

      case 403:
        // MessageBox.confirm('您的令牌已失效，您可以取消停留在这个页面，或再次登录', '确认登出', {
        //   confirmButtonText: '重新登陆',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload()
        //   })
        // })
        MessageTip({
          message: message
        });
        break;

      case 405:
        MessageTip({
          message: message
        });
        break;

      case 406:
        MessageTip({
          message: message
        });
        break;

      case 408:
        MessageTip({
          message: message
        });
        break;

      case 409:
        MessageTip({
          message: message
        });
        break;

      case 500:
        MessageTip({
          type: 'warning',
          message: message
        });
        break;

      default:
    }
  }

  return Promise.reject(error);
});
var _default = service;
exports.default = _default;