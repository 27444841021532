exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mt-10{margin-top:10px}.cooperation-header{height:100px;background-color:#fff;padding:20px;border-radius:1.25rem;-webkit-box-shadow:0px 2px 4px 0px rgba(49,37,82,0.14);box-shadow:0px 2px 4px 0px rgba(49,37,82,0.14);margin-bottom:30px;font-size:16px}.cooperation-header .cooperation-name{margin-bottom:20px}.cooperation-title{height:100px;background-color:#fff;text-align:center;padding:1.25rem;border-radius:1.25rem;-webkit-box-shadow:0px 2px 4px 0px rgba(49,37,82,0.14);box-shadow:0px 2px 4px 0px rgba(49,37,82,0.14);margin-bottom:30px;font-size:14px}.cooperation-total{height:36px;line-height:36px;font-size:16px}.cooperation-select{margin-bottom:20px}.cooperation-num{font-size:30px;font-weight:700;color:#4c28d0;margin-left:-3%}.cooperation-price{color:#f08730;font-size:30px}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "210px"
};