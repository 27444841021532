var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "email-wrap" }, [
    _c("div", { staticClass: "email-main" }, [
      _c(
        "div",
        { staticClass: "email-rt" },
        [
          _c("div", [_vm._v("Subject")]),
          _vm._v(" "),
          _c("el-input", {
            model: {
              value: _vm.emailObject.subject,
              callback: function($$v) {
                _vm.$set(_vm.emailObject, "subject", $$v)
              },
              expression: "emailObject.subject"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "email-main-con" },
            [
              _c("edit-div", {
                attrs: { value: _vm.emailObject.body, "can-edit": true },
                on: { "update-body": _vm.updateBody }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0)
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "send-btn" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.sendEmail } },
          [_vm._v("确认发送")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "email-foot" }, [
      _c("div", { staticClass: "info" }, [
        _c("p", [_vm._v("Regards")]),
        _vm._v(" "),
        _c("p", { staticClass: "title" }, [_vm._v("Mathllda Dow")]),
        _vm._v(" "),
        _c("p", [
          _vm._v("a: "),
          _c("em", [_vm._v("383 Georage, Sydney, NSW Australla")])
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("e: 383 Georage, Sydney, NSW Australla | p "),
          _c("em", [_vm._v("+86 18088138225")])
        ]),
        _vm._v(" "),
        _c("p", [_c("em", [_vm._v("Navigate Network -a 2dm initiative")])])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }