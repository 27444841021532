"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _vue = _interopRequireDefault(require("vue"));

var _Pagination = _interopRequireDefault(require("./Pagination"));

var _BackToTop = _interopRequireDefault(require("./BackToTop"));

var _Sticky = _interopRequireDefault(require("./Sticky"));

var _TipMsg = _interopRequireDefault(require("./TipMsg"));

var _CountTo = _interopRequireDefault(require("./CountTo"));

var _PopView = _interopRequireDefault(require("./PopView"));

var _WordCloud = _interopRequireDefault(require("./Charts/WordCloud"));

var _ChartScatter = _interopRequireDefault(require("./Charts/ChartScatter"));

var _ChartLine = _interopRequireDefault(require("./Charts/ChartLine"));

var _NoData = _interopRequireDefault(require("./NoData"));

var _PanelGroup = _interopRequireDefault(require("./PanelGroup"));

var _SubTitle = _interopRequireDefault(require("./SubTitle"));

var _SvgIcon = _interopRequireDefault(require("./SvgIcon"));

// import CascaderLabel from './CascaderLabel'
// import SelectedLabel from './SelectedLabel'
_vue.default.component('Pagination', _Pagination.default);

_vue.default.component('back-to-top', _BackToTop.default);

_vue.default.component('sticky', _Sticky.default);

_vue.default.component('TipMsg', _TipMsg.default);

_vue.default.component('CountTo', _CountTo.default);

_vue.default.component('WordCloud', _WordCloud.default);

_vue.default.component('PopView', _PopView.default);

_vue.default.component('ChartScatter', _ChartScatter.default);

_vue.default.component('NoData', _NoData.default);

_vue.default.component('ChartLine', _ChartLine.default); // Vue.component('CascaderLabel', CascaderLabel)
// Vue.component('SelectedLabel', SelectedLabel)


_vue.default.component('Pagination', _Pagination.default);

_vue.default.component('PanelGroup', _PanelGroup.default);

_vue.default.component('SubTitle', _SubTitle.default);

_vue.default.component('SvgIcon', _SvgIcon.default);