"use strict";

var _interopRequireWildcard = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("/opt/build/repo/node_modules/core-js/modules/es6.array.iterator.js");

require("/opt/build/repo/node_modules/core-js/modules/es6.promise.js");

require("/opt/build/repo/node_modules/core-js/modules/es6.object.assign.js");

require("/opt/build/repo/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _lang = _interopRequireDefault(require("./lang"));

require("./icons");

require("./permission");

require("@/components");

var filters = _interopRequireWildcard(require("./filters"));

var _index2 = _interopRequireDefault(require("@/directive/permission/index.js"));

require("@/lib");

// a modern alternative to CSS resets
// global css
// internationalization
// icon
// permission control
// global filters
// 权限判断指令
_vue.default.use(_index2.default);

_vue.default.use(_elementUi.default, {
  size: 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
}); // register global utility filters


Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});