"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchBrandList = fetchBrandList;
exports.getBrandStatistics = getBrandStatistics;
exports.getBrandWallet = getBrandWallet;
exports.getBrandTransactions = getBrandTransactions;

var _request = _interopRequireDefault(require("@/utils/request"));

var _qs = _interopRequireDefault(require("qs"));

// 获取品牌列表数据
function fetchBrandList(params) {
  return (0, _request.default)({
    url: '/brand-users',
    method: 'get',
    params: params,
    paramsSerializer: function paramsSerializer(params) {
      return _qs.default.stringify(params);
    }
  });
} // 获取入驻品牌统计数据


function getBrandStatistics(params) {
  return (0, _request.default)({
    url: "/brand-users?sum=true",
    method: 'get',
    params: params
  });
} // 获取入驻品牌钱包信息


function getBrandWallet(params) {
  return (0, _request.default)({
    url: "/wallets",
    method: 'get',
    params: params
  });
} // 获取入驻品牌交易记录


function getBrandTransactions(params) {
  return (0, _request.default)({
    url: "/transactions",
    method: 'get',
    params: params,
    paramsSerializer: function paramsSerializer(params) {
      return _qs.default.stringify(params);
    }
  });
} // // 获取待挖掘kol列表
// export function getFollowUpList(params) {
//   return request({
//     url: '/api/kol/listKOL',
//     method: 'get',
//     params
//   })
// }
// // 新增待挖掘kol
// export function postKOLInfo(data) {
//   return request({
//     url: `/api/kol/addKOL`,
//     method: 'post',
//     data
//   })
// }
// // 获取待挖掘kol编辑状态信息
// export function getKOLInfo(id) {
//   return request({
//     url: `/api/kol/kolInfo/${id}`,
//     method: 'get'
//   })
// }
// // 编辑待挖掘kol信息
// export function patchKOLInfo(id, data) {
//   return request({
//     url: `/api/kol/editKOL/${id}`,
//     method: 'put',
//     data
//   })
// }
// // 获取用户平台信息
// export function getInfluencersDetail(params) {
//   return request({
//     url: `/api/kol/platform`,
//     method: 'get',
//     params
//   })
// }
// // 获取平台帖子
// export function getInfluencerPost(params) {
//   return request({
//     url: `/api/kol/posts`,
//     method: 'get',
//     params
//   })
// }