"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
var _default = {
  name: 'NoData',
  props: {
    minHeight: {
      type: [Number, String],
      default: 320
    },
    desc: {
      type: String,
      default: '暂无数据'
    },
    imgSrc: {
      type: String,
      default: 'no-record'
    },
    size: {
      type: [String, Number],
      default: 120
    }
  }
};
exports.default = _default;