"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PostCard = _interopRequireDefault(require("../PostCard"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'PostList',
  components: {
    PostCard: _PostCard.default
  },
  props: {
    column: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      defaultImg: require('@/assets/images/common/default-img.png')
    };
  },
  methods: {
    handleViewPostDetail: function handleViewPostDetail(link) {
      this.$emit('handleViewPostDetail', link);
    }
  }
};
exports.default = _default2;