"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _echarts = _interopRequireDefault(require("@/utils/echarts"));

var _resize = _interopRequireDefault(require("../mixins/resize"));

//
//
//
var _default2 = {
  name: 'LineChart',
  mixins: [_resize.default],
  props: {
    chartData: {
      type: Object,
      default: function _default() {}
    },
    colors: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    chartData: function chartData() {
      this.filterData();
    }
  },
  mounted: function mounted() {
    this.filterData();
  },
  methods: {
    filterData: function filterData() {
      var _this = this;

      if (this.chartData.legend.length <= 0) {
        return;
      }

      var option = {
        grid: {
          left: 20,
          right: 0,
          bottom: 20,
          top: 100,
          containLabel: true
        },
        xAxis: {
          // name: '时间',
          type: 'category',
          data: []
        },
        tooltip: {},
        yAxis: {
          name: '粉丝',
          type: 'value',
          max: 150,
          // nameLocation: 'middle',
          // nameGap: 50,
          boundaryGap: true,
          nameTextStyle: {
            fontSize: 14,
            color: '#333'
          }
        },
        legend: {
          data: [],
          left: 'center',
          top: 0,
          itemGap: 130,
          textStyle: {
            rich: {
              a: {
                fontSize: 14,
                verticalAlign: 'top',
                align: 'center',
                padding: [0, 0, 28, 0],
                color: '#333'
              },
              b: {
                fontSize: 16,
                fontWeight: 'bold',
                align: 'center',
                color: '#333',
                padding: [0, 10, 0, 0],
                lineHeight: 25
              }
            }
          },
          formatter: function formatter(name) {
            _this.chartData.legend;
            var tpl = _this.chartData.legend;
            var filterData = tpl.filter(function (item) {
              return item.name === name;
            });
            return ['{a|' + filterData[0].name + '}', '{b|' + filterData[0].value + '}'].join('\n');
          }
        },
        series: []
      };
      option.yAxis.max = this.chartData.max + 3;
      option.xAxis.data = this.chartData.times;
      option.legend.data = this.chartData.legend;
      var tplData = this.chartData.data;

      for (var i = 0; i < tplData.length; i++) {
        tplData[i].smooth = true;
        tplData[i].type = 'line';
        tplData[i].lineStyle = {
          color: this.colors[i]
        };
        tplData[i].itemStyle = {
          'normal': {
            color: this.colors[i]
          }
        };
      }

      option.series = tplData;
      this.draw(option);
    },
    draw: function draw(option) {
      // 实例化echarts对象
      var myChart = _echarts.default.init(this.$refs.LineChart);

      myChart.setOption(option);
    }
  }
};
exports.default = _default2;